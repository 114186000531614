import * as constant from '../../Constants/HotelConstants/HotelCurdConstants';
import { ShowPopup } from '../../Alerts/Sweetalert';
import instance from '../../../utils/_utils';

export const GetHotelRegister = (formdata, currentUserid) => {
  return async (dispatch) => {
    dispatch({ type: constant.HOTEL_ADD_API_LOADING });
    try {
      const response = await instance.post(`/hotel/register/${currentUserid}`, formdata);
      if (response.status === 2000) {
        dispatch({ type: constant.HOTEL_ADD_API_SUCCESS, payload: response.data });
        ShowPopup({ error: response.data.error, message: 'Hotel Added Successfylly' });
      } else {
        ShowPopup({ error: response.data.error, message: response.data.message });
      }
    } catch (error) {
      dispatch({ type: constant.HOTEL_ADD_API_ERROR });
      ShowPopup({ error: true, message: 'Server Error' });
    }
  };
};

export const GetUserHotelData = (id, role) => {
  const roleQuery = new URLSearchParams({ role }).toString();
  return async (dispatch) => {
    dispatch({ type: constant.HOTEL_GET_API_LOADING });
    try {
      const respone = await instance.get(`/hotel/getusershotel/${id}?${roleQuery}`);
      if (respone.status === 200) {
        dispatch({ type: constant.HOTEL_GET_API_SUCCESS, payload: respone.data.data });
      }
    } catch (error) {
      dispatch({ type: constant.HOTEL_GET_API_ERROR });
    }
  };
};

// Update the hotel Data
export const GetUpdateTheHotelData = (id, formdata) => {
  return async (dispatch) => {
    dispatch({ type: constant.HOTEL_GET_UPDATE_API_LOADING });
    try {
      const response = await instance.patch(`/hotel/updatedetails/${id}`, formdata);
      if (response.status === 200) {
        dispatch({ type: constant.HOTEL_GET_UPDATE_API_SUCCESS, payload: response.data.data });
      }
    } catch (error) {
      dispatch({ type: constant.HOTEL_GET_UPDATE_API_ERROR });
    }
  };
};

export const HotelStatsAction = (vendorid) => {
  return async (dispatch) => {
    dispatch({ type: constant.HOTEL_GET_STATS_API_LOADING });
    try {
      const response = await instance.patch(`/analytics/partner/booking/chart/${vendorid}`);
      if (response.status === 200) {
        dispatch({ type: constant.HOTEL_GET_STATS_API_SUCCESS, payload: response.data.data });
      }
    } catch (error) {
      dispatch({ type: constant.HOTEL_GET_STATS_API_ERROR });
    }
  };
};

export const AdminVendorCountAction = () => {
  return async (dispatch) => {
    dispatch({ type: constant.ADMINVENDOR_GET_STATS_API_LOADING });
    try {
      const response = await instance.get(`/analytics/admin/partners/info`);
      if (response.status === 200) {
        dispatch({ type: constant.ADMINVENDOR_GET_STATS_API_SUCCESS, payload: response.data.data });
      }
    } catch (error) {
      dispatch({ type: constant.ADMINVENDOR_GET_STATS_API_ERROR });
    }
  };
};

export const AdminAddCountAction = () => {
  return async (dispatch) => {
    dispatch({ type: constant.ADMINADD_GET_STATS_API_LOADING });
    try {
      const response = await instance.get(`/analytics/admin/hotel-added-by`);
      if (response.status === 200) {
        dispatch({ type: constant.ADMINADD_GET_STATS_API_SUCCESS, payload: response.data.data });
      }
    } catch (error) {
      dispatch({ type: constant.ADMINADD_GET_STATS_API_ERROR });
    }
  };
};