import * as constant from '../../Constants/HotelConstants/HotelCurdConstants';

const initialState = {
  isLoading: false,
  data: [],
  isError: false,
};

const GetHotelAddReducers = (state = initialState, action) => {
  switch (action.type) {
    case constant.HOTEL_ADD_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.HOTEL_ADD_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.HOTEL_ADD_API_ERROR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

const UpdateHotelReducers = (state = initialState, action) => {
  switch (action.type) {
    case constant.HOTEL_GET_UPDATE_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.HOTEL_GET_UPDATE_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.HOTEL_GET_UPDATE_API_ERROR:
      return {
        ...state,
        isError: true,
      };

    default:
      return state;
  }
};

const HotelStatsReducers = (state = initialState, action) => {
  switch (action.type) {
    case constant.HOTEL_GET_STATS_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.HOTEL_GET_STATS_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.HOTEL_GET_STATS_API_ERROR:
      return {
        ...state,
        isError: true,
      };

    default:
      return state;
  }
};

const AdminVendorCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.ADMINVENDOR_GET_STATS_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.ADMINVENDOR_GET_STATS_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.ADMINVENDOR_GET_STATS_API_ERROR:
      return {
        ...state,
        isError: true,
      };

    default:
      return state;
  }
};

const AdminAddCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.ADMINADD_GET_STATS_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.ADMINADD_GET_STATS_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.ADMINADD_GET_STATS_API_ERROR:
      return {
        ...state,
        isError: true,
      };

    default:
      return state;
  }
};

export { GetHotelAddReducers, UpdateHotelReducers, HotelStatsReducers, AdminVendorCountReducer, AdminAddCountReducer };
