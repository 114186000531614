import * as constant from "../../Constants/VendorConstant/VendorGetAllConstants";

const initialValues = {
    isLoading: false,
    data: [],
    isError: false
}


const GetfacilitiesReducer = (state = initialValues, action) => {
    switch (action.type) {
        case constant.GET_FACILITY_AND_AMENITIES_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GET_FACILITY_AND_AMENITIES_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }
        case constant.GET_FACILITY_AND_AMENITIES_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }

        default:
            return state;
    }
}

export default GetfacilitiesReducer
