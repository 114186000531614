import React from 'react'
import { Grid } from '@mui/material'
import Bread from '../components/Bread/Bread'
import { AppWidgetSummary } from '../sections/@dashboard/app'
import KycTable from '../components/Tables/KycTable'

const KycVerification = () => {
    return (
        <div>
            <Bread tabName={'Kyc Verification'} />
            <Grid container spacing={3} >
                {/* Add Own Hotel */}
                <Grid item xs={12} sm={4} md={4}>
                    <AppWidgetSummary
                        title="Requested"
                        total={10}
                        color="info"
                        icon={'ic:baseline-group'}
                    //   onClick={handleOpen}
                    />
                </Grid>

                {/* Add Vendor Hotel */}
                <Grid item xs={12} sm={4} md={4}>
                    <AppWidgetSummary
                        title="Approved"
                        total={1723315}
                        color="success"
                        icon={'prime:circle-fill'}
                    //   onClick={handleOpen}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <AppWidgetSummary
                        title="Rejected"
                        total={1723315}
                        color="error"
                        icon={'prime:circle-fill'}
                    //   onClick={handleOpen}
                    />
                </Grid>

                {/* VHotel Component */}
                <Grid item xs={12} sm={12} md={12}>
                    <KycTable />
                </Grid>
            </Grid>
        </div>
    )
}

export default KycVerification