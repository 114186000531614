import React, { useState } from 'react';
import {
    Box,
    Button,
    Fade,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import OfferTable from '../components/Tables/OfferTable';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Bread from '../components/Bread/Bread';
import { UseStateManager } from '../Context/StateManageContext';
import { CreateHotelioOffersAction } from '../Store/Actions/HotelioOffersActions/HotelioOffersAction';

const Discount = () => {

    const dispatch = useDispatch();

    // State to control the visibility of the modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const RoomType = useSelector((state) => state.AllRoomTypeReducers.data)

    const AddOffer = ({ open, handleClose }) => {

        const [discountData, setDiscountData] = React.useState({
            code: '',
            title: '',
            description: '',
            validation: {
                upto: '',
                validFor: '',
                minTransactions: '',
                roomtype: null
            },
            codeDiscount: {
                amount: 0,
                percentage: 0,
            }
        });

        const handleInputChange = (e) => {
            setDiscountData({
                ...discountData,
                [e.target.name]: e.target.value,
            });
        };

        const isFormValid = () => {
            // Add validation logic here
            if (
                discountData.code &&
                discountData.title &&
                discountData.description &&
                discountData.minTransactions &&
                (discountData.amount || discountData.percentage) &&
                discountData.roomtype &&
                discountData.validFor &&
                discountData.upto
            ) {
                return true;
            }
            return false;
        };

        const handleSubmit = () => {
            if (isFormValid()) {
                const data = {
                    code: discountData?.code,
                    title: discountData?.title,
                    description: discountData?.description,
                    validation: {
                        upto: discountData?.upto,
                        validFor: discountData?.validFor,
                        minTransactions: discountData?.minTransactions,
                        roomtype: discountData?.roomtype
                    },
                    codeDiscount: {
                        amount: discountData.amount,
                        percentage: discountData.percentage,
                    }
                }
                dispatch(CreateHotelioOffersAction(data))
                handleClose()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: 'Please fill in all fields before submitting.',
                });
            }
        };


        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={modalStyle}>
                        <Typography variant='h4'>Add Offer</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="code">Code Name</InputLabel>
                                <TextField
                                    fullWidth
                                    name="code"
                                    type="text"
                                    sx={{ textTransform: 'uppercase' }}
                                    placeholder='SUNDAYOFF20'
                                    value={discountData.code}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="title">Code Title</InputLabel>
                                <TextField
                                    fullWidth
                                    name="title"
                                    placeholder='Enter Offer title'
                                    type="text"
                                    value={discountData.title}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <InputLabel htmlFor="description">Code Description</InputLabel>
                                <TextField
                                    fullWidth
                                    name="description"
                                    type="text"
                                    value={discountData.description}
                                    placeholder='Explain the offer for customer understanding'
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="minTransactions">Min Value</InputLabel>
                                <TextField
                                    fullWidth
                                    name="minTransactions"
                                    type="number"
                                    placeholder='Enter minimum value'
                                    value={discountData.minTransactions}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="amount">Discount (In Rupees)</InputLabel>
                                <TextField
                                    fullWidth
                                    name="amount"
                                    type="number"
                                    placeholder='Enter Amount'
                                    value={discountData.amount}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="percentage">Discount (In Percentage)</InputLabel>
                                <TextField
                                    fullWidth
                                    name="percentage"
                                    type="number"
                                    value={discountData.percentage}
                                    placeholder='Add Percentage value'
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="roomtype">Applicable</InputLabel>
                                <Select
                                    fullWidth
                                    label="Applicable"
                                    name="roomtype"
                                    value={discountData.roomtype}
                                    onChange={handleInputChange}
                                >
                                    {
                                        RoomType.map((item, index) => (
                                            <MenuItem key={index} value={item._id}>{item?.title}</MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="validFor">Applied On</InputLabel>
                                <Select
                                    fullWidth
                                    label="Applied On"
                                    name="validFor"
                                    value={discountData.validFor}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value={'customer'}>Customer</MenuItem>
                                    <MenuItem value={'agent'}>Agent</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='body1'>Expiry Date</Typography>
                                <TextField
                                    fullWidth
                                    error
                                    name="upto"
                                    type="date"
                                    value={discountData.upto}
                                    onChange={handleInputChange}
                                    inputProps={{
                                        min: new Date().toISOString().split('T')[0],
                                    }}
                                    focused
                                    helperText="Offer Start Date is Today"
                                />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="space-between">
                                <Button variant="outlined" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={handleSubmit}>
                                    Add Offer
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        );
    };

    return (
        <div>
            <AddOffer open={open} handleClose={handleClose} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Bread icon={'ic:twotone-discount'} tabName={'Manage Offers'} />
                </Grid>
                <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                    <Button onClick={handleOpen} variant="contained">
                        Add Offer
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Total Offers"
                        total={11}
                        color="info"
                        icon={'ic:twotone-discount'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Active Offers"
                        total={10}
                        color="success"
                        icon={'bi:circle-fill'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="In-Active Offers"
                        total={1}
                        color="error"
                        icon={'bi:circle-fill'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Claimed Offers"
                        total={10}
                        color="warning"
                        icon={'wpf:cut-paper'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <OfferTable />
                </Grid>
            </Grid>
        </div>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    overflow: 'auto', // Added scrolling property
};

export default Discount;
