import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Autocomplete,
} from '@mui/material';
import { UseStateManager } from '../../Context/StateManageContext';
import { GetAddTheNewRooom } from '../../Store/Actions/RoomManagement/roomManagementActions';
import WaitLoader from '../../utils/WaitLoader';
import { GetUserHotelData } from '../../Store/Actions/HotelActions/HotelCurdAction';

const AddRoomForm = ({ handleClose, manageRoom }) => {
  const {
    currentUser,
    ammenitiesList,
    allFacilities,
    setAllFacilities,
    roomList,
    GetAmenitiesRoomTypeFacilitiesPropertyTypes,
  } = UseStateManager();
  // Add room result
  const addedRoom = useSelector((state) => state.GetAddTheRoomReducer);

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  // State to store form data
  const [formData, setFormData] = useState({});

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the formData to your API or perform any necessary actions
    console.log(formData);
  };

  const roomListWithLabel = roomList.data?.map((x) => ({ label: x.title, id: x._id }));

  const HandleRoomCreation = (manageRoom, formData) => {
    setLoader(true);
    dispatch(GetAddTheNewRooom(manageRoom, formData)).then(() => {
      dispatch(GetUserHotelData(currentUser?._id, currentUser?.role)).then(() => {
        setLoader(false);
        handleClose();
      });
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Number of Rooms"
            name="counts"
            value={formData.counts || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={roomListWithLabel}
            value={formData.roomType || ''}
            onChange={(event, newValue) => setFormData({ ...formData, roomType: newValue.id })}
            renderInput={(params) => <TextField {...params} label="Search Room Category" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="number"
            label="Price per Room/Night"
            name="price"
            value={formData.price || ''}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <div>
        <Button
          sx={{ m: 1 }}
          variant="contained"
          onClick={() => {
            HandleRoomCreation(manageRoom, formData);
          }}
          color="primary"
          type="button"
        >
          save
        </Button>
        <Button onClick={handleClose} sx={{ m: 1 }} variant="outlined" color="primary">
          Cancel
        </Button>
      </div>
      <WaitLoader Loading={loader} />
    </form>
  );
};

export default AddRoomForm;
