import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { API_URL } from '../config';
import instance from '../utils/_utils';

const StateManagerContext = createContext();

const StateManagerProvider = ({ children }) => {
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLagtitude] = useState(null);

  const [fetchedAddress, setFetchedAddress] = useState(null);
  const [roomList, setroomList] = useState(null);
  const [ammenitiesList, setammenitiesList] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [allFacilities, setAllFacilities] = useState(null);
  const [propertyType, setPropertyType] = useState(null);

  // Kyc state
  const [isKyc, setIsKyc] = useState(null);

  const [Loader, setLoader] = useState(false);

  const [uploadedImage, setUploadedImage] = useState(null);

  // upload Image

  const UploadFileImage = async (formdata, userId) => {
    try {
      const isResponse = await instance.post(`/util/uploadfile/${userId}`, formdata);
      if (isResponse.status === 200) {
        Swal.fire({
          title: 'Image Uploaded!',
          text: 'Modal with a custom image.',
          imageUrl: `${API_URL}/uploads/${isResponse.data.fileName}`,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Uploaded',
        });
        setUploadedImage(isResponse.data.fileName);
      }
    } catch (error) {
      Swal.fire({
        title: 'Image Upload Failed',
        text: 'Image upload failed . Please try Again',
        icon: 'error',
      });
    }
  };

  // get geo location

  const GetGeoLocation = () => {
    // Get the user's geolocation when the component mounts
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const options = {
            method: 'GET',
            url: 'https://forward-reverse-geocoding.p.rapidapi.com/v1/reverse',
            params: {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
              'accept-language': 'en',
              polygon_threshold: '0.0',
            },
            headers: {
              'X-RapidAPI-Key': 'a74ca864b9msh8c372c2c7aa877ap1e1809jsna5adf0f7de32',
              'X-RapidAPI-Host': 'forward-reverse-geocoding.p.rapidapi.com',
            },
          };
          setLongitude(position.coords.longitude);
          setLagtitude(position.coords.latitude);
          try {
            const response = await axios.request(options);
            setFetchedAddress(response.data);
          } catch (error) {
            console.error(error);
          }
        },
        (error) => {
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  // // Get all the room list

  // const GetAllRoomList = async () => {
  //     try {
  //         const response = await instance.get(`/roomtype/get`);
  //         if (response.status === 200) {
  //             const responseData = response.data;
  //             console.info(responseData)
  //             setroomList(responseData);
  //         } else {
  //             console.error('API request failed with status:', response.status);
  //         }
  //     } catch (error) {
  //         console.error('Error fetching data from server:', error);
  //     }
  // };

  // // Get all the amenities

  // const GetAllAmenities = async () => {
  //     try {
  //         const response = await instance.get(`/amenity/get`);
  //         if (response.status === 200) {
  //             const responseData = response.data;
  //             console.info(responseData)
  //             setammenitiesList(responseData);
  //         } else {
  //             console.error('API request failed with status:', response.status);
  //         }
  //     } catch (error) {
  //         console.error('Error fetching data from server:', error);
  //     }
  // };

  // Get vendor login
  const GetVendorLogIn = async (formdata, role) => {
    try {
      let response;

      if (role === 'admin') {
        response = await instance.post(`/api/admin/login`, formdata);
      } else if (role === 'vendor') {
        response = await instance.post(`/api/vendor/login`, formdata);
      }

      if (response && response.status === 200) {
        const userData = response.data.data;
        sessionStorage.setItem('user', JSON.stringify(userData));
        window.localStorage.setItem('user', JSON.stringify(userData));
        sessionStorage.setItem('test', JSON.stringify(response.data));
        Swal.fire({ text: 'login success', icon: 'success' });
        setIsKyc(userData.kyc[0]);
        setCurrentUser(userData);
      }
    } catch (error) {
      // You can also log the error or display it in an error message
      console.error('Login API Error:', error);
      // showErrorAlert("Login Failed");
    }
  };

  const GetAmenitiesRoomTypeFacilitiesPropertyTypes = async () => {
    try {
      const response = await instance.get('/api/get/hotel/sub-base');
      if (response.status === 200) {
        setAllFacilities({ data: response.data.data?.facilities });
        setPropertyType({ data: response.data.data?.propertyTypes });
        setroomList({ data: response.data.data?.roomType });
        setammenitiesList({ data: response.data.data?.amenities });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get vendor data update
  const GetVendorUpdatedData = async (id) => {
    try {
      const vendorData = await instance.get(`/api/vendor/get/${id}`);
      if (vendorData.status === 200) {
        sessionStorage.setItem('user', JSON.stringify(vendorData.data.data[0]));
        window.localStorage.setItem('user', JSON.stringify(vendorData.data.data[0]));
        setCurrentUser(vendorData.data.data[0]);
        setIsKyc(vendorData.data?.data[0]?.kyc[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get vendor data update
  const DeleteBankAccount = async (vendorid, id) => {
    try {
      const deletedBankData = await instance.delete(`/bank/bank-details/delete/${vendorid}/${id}`);
      if (deletedBankData.status === 200) {
        // Handle success, if needed
        console.log('Bank account deleted successfully');
        toast.success('Bank account deleted successfully')
      }
    } catch (error) {
      // Handle error
      console.error('Error deleting bank account:', error);
    }
  };


  // getall the kyc request
  const iSKycRequest = async (id) => {
    try {
      const query = new URLSearchParams({ id }).toString();
      const response = await instance.get(`/kyc/getall?${query}`);
      if (response.status === 200) {
        setIsKyc(response.data.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // // To get all the Property types
  // const GetAllPropertytypes = async () => {
  //     try {
  //         const response = await instance.get("/property-type/get")
  //         if (response.status === 200) {
  //             setPropertyType(response.data)
  //             console.info(response.data)
  //         }
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  // const GetAllTheFacilities = async () => {
  //     try {
  //         const response = await instance.get("/facility/get")
  //         if (response.status === 200) {
  //             console.info(response.data)
  //             setAllFacilities(response.data)
  //         }
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  // update the room data
  const UpdateRoomData = async (hotelid, roomid, formdata) => {
    try {
      const response = await instance.patch(`/hotel/room/update/${hotelid}/${roomid}`, formdata);
      if (response.status === 200) {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UploadImageUrl = async (userId, avatarFileName) => {
    try {
      const avatarUploadResponse = await axios.post(`${API_URL}/api/upload/avatar/${userId}`, {
        avatar: avatarFileName,
      });
      console.log('Avatar uploaded successfully:', avatarUploadResponse.data);

      setCurrentUser({
        ...currentUser,
        avatar: avatarFileName,
      });

    } catch (error) {
      console.log(error)
    }
  }

  const uploadSliderImages = async (fileName) => {
    try {
      const uploadSliderImage = await instance.get(`/slider/add-in-slider?imgurl=${fileName}`)
      if (uploadSliderImage.status === 200) {
        console.log(uploadSliderImage.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // GetAllRoomList()
    // GetAllAmenities()
    // GetAllPropertytypes()
    // GetAllTheFacilities()
    GetAmenitiesRoomTypeFacilitiesPropertyTypes();
    // UploadImageUrl()
  }, []);

  useEffect(() => {
    if (currentUser?.role === 'vendor') {
      GetVendorUpdatedData(currentUser?._id);
      iSKycRequest(currentUser?._id);
    }
  }, [currentUser?._id]);

  // Update currentUser when session storage changes

  return (
    <StateManagerContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        UploadFileImage,
        setUploadedImage,
        uploadedImage,
        uploadSliderImages,
        Loader,
        setLoader,
        GetGeoLocation,
        longitude,
        latitude,
        fetchedAddress,
        GetVendorLogIn,
        // GetAllRoomList,
        UploadImageUrl,
        roomList,
        setroomList,
        ammenitiesList,
        setammenitiesList,
        iSKycRequest,
        GetVendorUpdatedData,
        DeleteBankAccount,
        isKyc,
        setIsKyc,
        propertyType,
        setPropertyType,
        allFacilities,
        setAllFacilities,
        GetAmenitiesRoomTypeFacilitiesPropertyTypes,
        UpdateRoomData,
      }}
    >
      {children}
    </StateManagerContext.Provider>
  );
};

const UseStateManager = () => {
  return useContext(StateManagerContext);
};

export { UseStateManager, StateManagerProvider };
