import axios from 'axios'
import instance from '../../../utils/_utils'
// import * as constant from '../../Constants/adminConstants/allRoomType'
import * as constant from '../../Constants/adminConstants/allPropertyType'



export const GetAllPropertyType = () => {
    return async (dispatch) => {
        dispatch({ type: constant.GET_ALL_PROPERTY_TYPE_LOADING })
        try {
            const response = await instance.get(`/property-type/get`)
            if (response.status === 200) {
                dispatch({ type: constant.GET_ALL_PROPERTY_TYPE_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.GET_ALL_PROPERTY_TYPE_ERROR })
        }
    }
}

export const GetAddPropertyType = (formData) => {
    return async (dispatch) => {
        dispatch({ type: constant.PUT_ALL_PROPERTY_TYPE_LOADING })
        try {
            const response = await instance.post(`/property-type/add`, formData)
            if (response.status === 200) {
                dispatch({ type: constant.PUT_ALL_PROPERTY_TYPE_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.PUT_ALL_PROPERTY_TYPE_ERROR })
        }
    }
}

export const UpdatePropertyType = (propertyTypeId, formdata) => {
    return async (dispatch) => {
        dispatch({ type: constant.UPDATE_ALL_PROPERTY_TYPE_LOADING })
        try {
            const response = await instance.get(`/property-type/update/${propertyTypeId}`, formdata)
            if (response.status === 200) {
                dispatch({ type: constant.UPDATE_ALL_PROPERTY_TYPE_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.UPDATE_ALL_PROPERTY_TYPE_ERROR })
        }
    }
}

export const RemovePropertyType = (propertyTypeId) => {
    return async (dispatch) => {
        dispatch({ type: constant.REMOVE_ALL_PROPERTY_TYPE_LOADING })
        try {
            const response = await instance.get(`/property-type/delete?_id=${propertyTypeId}`)
            if (response.status === 200) {
                dispatch({ type: constant.REMOVE_ALL_PROPERTY_TYPE_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.REMOVE_ALL_PROPERTY_TYPE_ERROR })
        }
    }
}