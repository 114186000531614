export const CREATE_LOCATION_API_LOADING = 'CREATE_LOCATION_API_LOADING';
export const CREATE_LOCATION_API_SUCCESS = 'CREATE_LOCATION_API_SUCCESS';
export const CREATE_LOCATION_API_ERROR = 'CREATE_LOCATION_API_ERROR';

export const UPDATE_LOCATION_API_LOADING = 'UPDATE_LOCATION_API_LOADING';
export const UPDATE_LOCATION_API_SUCCESS = 'UPDATE_LOCATION_API_SUCCESS';
export const UPDATE_LOCATION_API_ERROR = 'UPDATE_LOCATION_API_ERROR';

export const GET_LOCATION_API_LOADING = 'GET_LOCATION_API_LOADING';
export const GET_LOCATION_API_SUCCESS = 'GET_LOCATION_API_SUCCESS';
export const GET_LOCATION_API_ERROR = 'GET_LOCATION_API_ERROR';
