export const HOTEL_ADD_API_LOADING = 'HOTEL_ADD_API_LOADING';
export const HOTEL_ADD_API_SUCCESS = 'HOTEL_ADD_API_SUCCESS';
export const HOTEL_ADD_API_ERROR = 'HOTEL_ADD_API_ERROR';

export const HOTEL_GET_API_LOADING = 'HOTEL_GET_API_LOADING';
export const HOTEL_GET_API_SUCCESS = 'HOTEL_GET_API_SUCCESS';
export const HOTEL_GET_API_ERROR = 'HOTEL_GET_API_ERROR';

export const HOTEL_GET_UPDATE_API_LOADING = 'HOTEL_GET_UPDATE_API_LOADING';
export const HOTEL_GET_UPDATE_API_SUCCESS = 'HOTEL_GET_UPDATE_API_SUCCESS';
export const HOTEL_GET_UPDATE_API_ERROR = 'HOTEL_GET_UPDATE_API_ERROR';

export const HOTEL_GET_STATS_API_LOADING = 'HOTEL_GET_STATS_API_LOADING';
export const HOTEL_GET_STATS_API_SUCCESS = 'HOTEL_GET_STATS_API_SUCCESS';
export const HOTEL_GET_STATS_API_ERROR = 'HOTEL_GET_STATS_API_ERROR';

export const ADMINVENDOR_GET_STATS_API_LOADING = 'ADMINVENDOR_GET_STATS_API_LOADING';
export const ADMINVENDOR_GET_STATS_API_SUCCESS = 'ADMINVENDOR_GET_STATS_API_SUCCESS';
export const ADMINVENDOR_GET_STATS_API_ERROR = 'ADMINVENDOR_GET_STATS_API_ERROR';

export const ADMINADD_GET_STATS_API_LOADING = 'ADMINADD_GET_STATS_API_LOADING';
export const ADMINADD_GET_STATS_API_SUCCESS = 'ADMINADD_GET_STATS_API_SUCCESS';
export const ADMINADD_GET_STATS_API_ERROR = 'ADMINADD_GET_STATS_API_ERROR';