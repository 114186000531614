import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BookingTable from '../components/Tables/BookingTable'
import { AppWidgetSummary } from '../sections/@dashboard/app';
import { UseStateManager } from '../Context/StateManageContext';
import { GetAdminBookingStatsAction, GetVendorBookingStatsAction } from '../Store/Actions/BookingActions/GetBookingActions';

const MyBooking = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { currentUser, isKyc } = UseStateManager();
    React.useEffect(() => {
        dispatch(GetVendorBookingStatsAction(currentUser?._id))
    }, [currentUser])

    React.useEffect(() => {
        dispatch(GetAdminBookingStatsAction())
    }, [])

    const VENDORBOOKINGSTATS = useSelector((state) => state.VendorBookingStatsReducer.data[0])
    const ADMINBOOKINGSTATS = useSelector((state) => state.GetAdminBookingStatsReducer.data)
    console.log(ADMINBOOKINGSTATS)
    const totalVendorBookings = VENDORBOOKINGSTATS?.allBookings || '0';
    const confirmedVendorBookings = VENDORBOOKINGSTATS?._status.find(status => status._id === 'confirmed')?.total || '0';
    const holdingRooms = VENDORBOOKINGSTATS?._status.find(status => status._id === 'pending')?.total || '0';
    const canceledVendorBookings = VENDORBOOKINGSTATS?._status.find(status => status._id === 'canceled')?.total || '0';

    const totalAdminBookings = ADMINBOOKINGSTATS?.[0]?.total + ADMINBOOKINGSTATS[1]?.total + ADMINBOOKINGSTATS[2]?.total || '0';
    const confirmedAdminBookings = ADMINBOOKINGSTATS?.[1]?.total || '0';
    const canceledAdminBookings = ADMINBOOKINGSTATS?.[2]?.total || '0';

    return (
        <Box>
            {isKyc?.isVerified === 'approved' || currentUser?.role === 'admin' ? <Grid container spacing={3}>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Total Bookings"
                        total={currentUser?.role === 'admin' ? totalAdminBookings : totalVendorBookings}
                        color="warning"
                        icon={'subway:book'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Confirmed Bookings"
                        total={currentUser?.role === 'admin' ? confirmedAdminBookings : confirmedVendorBookings}
                        color="success"
                        icon={'material-symbols:thumb-up-rounded'}
                    />
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Holding Rooms"
                        total={holdingRooms}
                        color="secondary"
                        icon={'mingcute:auto-hold-line'}
                    />
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Cancel Bookings"
                        total={currentUser?.role === 'admin' ? canceledAdminBookings : canceledVendorBookings}
                        color="error"
                        icon={'material-symbols:cancel'}
                    />
                </Grid>

                {/* BookingTable Component */}
                <Grid item xs={12} sm={12} md={12}>
                    <BookingTable />
                </Grid>
            </Grid> :
                <Grid Grid container spacing={1}>
                    <Grid xs={12} item textAlign={'center'}>
                        <Typography variant='h5'>First Complete you profile using below button</Typography>
                        <Button onClick={() => navigate('/dashboard/profile')} variant='contained' color='error' >Verify</Button>
                    </Grid>
                </Grid>}
        </Box>
    )
}

export default MyBooking