import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Rating, Paper } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { GetAllReviewRatingsByIdAction, DeleteReviewRatingsAction } from '../../Store/Actions/ReviewRatnigActions/ReviewRatnigAction';
import { UseStateManager } from '../../Context/StateManageContext';

export default function ReviewRatingTable() {

  const dispatch = useDispatch();
  const { currentUser } = UseStateManager()
  const HotelReviewList = useSelector((state) => state.GetAllReviewRatingsByIdReducer.data)

  React.useEffect(() => {
    dispatch(GetAllReviewRatingsByIdAction(currentUser?._id))
  }, [currentUser])


  const handleDeleteReviewRating = async (ratingReviewId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await dispatch(DeleteReviewRatingsAction(ratingReviewId));
        Swal.fire(
          'Deleted!',
          'Your review has been deleted.',
          'success'
        );
        await dispatch(GetAllReviewRatingsByIdAction());
      }
    } catch (error) {
      console.error('Error deleting Review:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to delete review. Please try again.',
      });
    }
  };


  // map the id in the data
  const DataWithID = (data) => {
    const newData = [];

    if (Array.isArray(data) && data.length > 0) {
      data.forEach((item, index) => {
        newData.push({ ...item, id: index + 1 });
      });
    } else {
      newData.push({ id: 0 });
    }

    return newData;
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Sr. No',
      width: 100,
      renderCell: (params) => params.row?.id
    },
    {
      field: 'customer',
      headerName: 'Name',
      width: 150,
      renderCell: (params) => params.row?.customer?.[0]?.name
    },
    {
      field: 'hotel',
      headerName: 'Property Name',
      width: 150,
      renderCell: (params) => params.row?.hotel?.[0]?.hotelName
    },
    {
      field: 'ratings',
      headerName: 'Rating',
      width: 200,
      renderCell: (params) => <Rating name="read-only" value={params.row?.ratings} readOnly />
    },
    // {
    //   field: 'country',
    //   headerName: 'Country',
    //   width: 150,
    // },
    {
      field: 'createdAt',
      headerName: 'Rating Date',
      width: 150,
      renderCell: (params) => moment(params.row?.createdAt).format('MM Do YYYY')
    },
    {
      field: 'valueOfMoney',
      headerName: 'Value of Money',
      align: 'center', headerAlign: 'center',
      width: 100
    },
    {
      field: 'cleanliness',
      headerName: 'Cleanliness',
      align: 'center',
      headerAlign: 'center',
      width: 100
    },
    {
      field: 'comfort',
      headerName: 'Comfort',
      align: 'center',
      headerAlign: 'center',
      width: 100
    },
    {
      field: 'message',
      headerName: 'Review comment',
      width: 300,
      renderCell: (params) => (
        <div><p>{params.row?.message || 'NA'}</p></div>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="text"
          color="error"
        >
          <DeleteForeverIcon onClick={() => handleDeleteReviewRating(params.row?._id)} fontSize='large' />
        </Button>
      ),
    },
  ];


  return (
    <Paper sx={{ minheight: '100vh', marginTop: '10px' }}>
      <DataGrid
        sx={{ overflowX: 'scroll' }}
        rows={DataWithID(HotelReviewList)}
        // rows={HotelReviewList}
        columns={columns}
        density="comfortable"
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        slots={{
          Toolbar: GridToolbar,
        }}
      />
    </Paper>
  );
}
