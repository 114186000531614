import React from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import { Icon } from '@iconify/react';

const Bread = ({ icon, tabName }) => {
    return (
        <Breadcrumbs sx={{ marginBottom: 2, display: 'flex', alignItems: 'center' }} aria-label="breadcrumb">
            <Link
                underline="hover"
                style={{ display: 'flex', alignItems: 'center', color: '#ff5300' }}
                href="/dashboard/app"
            >
                <Icon icon="ic:twotone-home" width="25" height="25" />
                &nbsp;	Home &nbsp;	
            </Link>
            <Typography
                style={{ display: 'flex', alignItems: 'center' }}
                color="text.primary"
            >
                &nbsp;
                <Icon icon={icon} width="20" height="20" />
                &nbsp;	{tabName}
            </Typography>
        </Breadcrumbs>
    )
}

export default Bread