import * as constant from "../../Constants/VendorConstant/VendorGetAllConstants";


const initialState = {
    isLoading: false,
    data: [],
    isError: false
}

const GetSingleHotelsDetails = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_SINGLE_VENDOR_API_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case constant.GET_SINGLE_VENDOR_API_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            }
        case constant.GET_SINGLE_VENDOR_API_ERROR:
            return {
                ...state,
                isError: true,
                isLoading: false
            }

        default:
            return state;
    }
}


export default GetSingleHotelsDetails