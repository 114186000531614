import * as constant from '../Constants/BankDetailsConstants';

const initialState = {
    loading: false,
    error: false,
    data: null,
};

const BankDetailReducers = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_BANK_DETAIL_LOADING:
            return {
                ...state,
                loading: true,
            };
        case constant.GET_BANK_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case constant.GET_BANK_DETAIL_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};

export default BankDetailReducers;
