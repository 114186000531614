import * as constant from '../../Constants/adminConstants/managecustomer_constant';


const initialState = {
    isLoading: false,
    isError: false,
    data: []
}


const GetAllCustomerReducers = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_ALL_CUSTOMER_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GET_ALL_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.GET_ALL_CUSTOMER_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}



export default GetAllCustomerReducers 