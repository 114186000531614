import { Backdrop, Modal } from '@mui/material';
import React from 'react';

const GlobalModel = ({ open, handleClose, content }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        display: 'flex', // Center the modal using Flexbox
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
        zIndex: '1',
        '@media (max-width: 768px)': {
          width: '90%', // Adjust the width for smaller screens
        },
        '@media (max-width: 480px)': {
          width: '100%', // Adjust the width for even smaller screens
        },
        overflowY: 'scroll',
      }}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      {content}
    </Modal>
  );
};

export default GlobalModel;
