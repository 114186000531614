export const HOTEL_BOOKINGBYID_API_LOADING = 'HOTEL_BOOKINGBYID_API_LOADING';
export const HOTEL_BOOKINGBYID_API_SUCCESS = 'HOTEL_BOOKINGBYID_API_SUCCESS';
export const HOTEL_BOOKINGBYID_API_ERROR = 'HOTEL_BOOKINGBYID_API_ERROR';

export const HOTEL_BOOKING_API_LOADING = 'HOTEL_BOOKING_API_LOADING';
export const HOTEL_BOOKING_API_SUCCESS = 'HOTEL_BOOKING_API_SUCCESS';
export const HOTEL_BOOKING_API_ERROR = 'HOTEL_BOOKING_API_ERROR';

export const HOTEL_VENDORBOOKING_API_LOADING = 'HOTEL_BOOKING_API_LOADING';
export const HOTEL_VENDORBOOKING_API_SUCCESS = 'HOTEL_BOOKING_API_SUCCESS';
export const HOTEL_VENDORBOOKING_API_ERROR = 'HOTEL_BOOKING_API_ERROR';

export const HOTEL_VENDORBOOKING_STATS_API_LOADING = 'HOTEL_BOOKING_STATS_API_LOADING';
export const HOTEL_VENDORBOOKING_STATS_API_SUCCESS = 'HOTEL_BOOKING_STATS_API_SUCCESS';
export const HOTEL_VENDORBOOKING_STATS_API_ERROR = 'HOTEL_BOOKING_STATS_API_ERROR';

export const HOTEL_ADMINBOOKING_STATS_API_LOADING = 'HOTEL_ADMINBOOKING_STATS_API_LOADING';
export const HOTEL_ADMINBOOKING_STATS_API_SUCCESS = 'HOTEL_ADMINBOOKING_STATS_API_SUCCESS';
export const HOTEL_ADMINBOOKING_STATS_API_ERROR = 'HOTEL_ADMINBOOKING_STATS_API_ERROR';