import React, { useEffect } from 'react'
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { GetAllBookingAction } from '../../Store/Actions/BookingActions/GetBookingActions';

const UpComingBookingTable = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllBookingAction());
  }, []);

  const UpcomingBookingData = useSelector(state => state.BookingReducers.data)


  const getTitleForRoom = (data) => {
    const { room, hotel } = data;

    if (hotel && hotel.rooms && room) {
      const matchingRoom = hotel.rooms.find((r) => r._id === room);

      if (matchingRoom && matchingRoom.roomType) {
        return matchingRoom.roomType.title;
      }
    }
    return null;
  };


  const getStatusContent = (paymentStatus) => {
    if (paymentStatus === 'expired') {
      return <p style={{ color: 'orange' }}>Session Expired</p>;
    }

    if (paymentStatus === 'confirmed') {
      return <p style={{ color: '#81c14b' }}>Paid</p>;
    }

    if (paymentStatus === 'canceled') {
      return <p style={{ color: 'red' }}>cancelled</p>;
    }

    return <p>{paymentStatus}</p>;
  };

  const DataWithID = (data) => {
    const newData = [];
    if (data !== undefined) {
      data.forEach((item, index) => {
        newData.push({ ...item, id: index + 1 });
      });
    } else {
      newData.push({ id: 0 });
    }
    return newData;
  };


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const columns = [
    {
      field: 'bookingId',
      headerName: 'Booking ID',
      width: 100,
    },
    {
      field: 'hotelName',
      headerName: 'Hotel Name',
      width: 300,
      fontWeight: 900,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <img
            style={{
              minWidth: '80px',
              height: '60px',
              objectFit: 'cover',
              borderRadius: '15px',
            }}
            src={params.row?.hotel?.hotelCoverImg} alt="hotelImage" />
          <div style={{ paddingLeft: '5px', wordWrap: 'break-word' }}>
            <h4 style={{ margin: '0px' }}>{params.row?.hotel?.hotelName}</h4>
            <p style={{ margin: '0px', wordWrap: 'break-word' }}>{params.row?.hotel?.address}</p>
          </div>
        </div >
      )
    },
    {
      field: 'guest',
      headerName: 'Guest Name',
      width: 200,
      valueFormatter: ({ value }) => value?.name
    },
    {
      field: 'guestContactDetails',
      headerName: 'Guest Contact Details',
      width: 200,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.guest?.mobileNo}</p>
          <p style={{ margin: '0px' }}>{params.row.guest?.email}</p>
        </div>
      )
    },
    {
      field: 'checkIn',
      headerName: 'Check In',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkIn).format('DD/MM/YY')}</p>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkIn).format('h:mm')}</p>
        </div>
      )
    },
    {
      field: 'checkOut',
      headerName: 'Check Out',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkOut).format('DD/MM/YY')}</p>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkOut).format('h:mm')}</p>
        </div>
      )
    },
    {
      field: 'numberOfRooms',
      headerName: 'No. Of Rooms',
      width: 100,
    },
    {
      field: 'numberOfGuests',
      headerName: 'No. Of Guests',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.numberOfGuests?.adults}</p>
        </div>
      )
    },
    {
      field: 'roomType',
      headerName: 'Room Type',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{getTitleForRoom(params.row)}</p>
        </div>
      )
    },
    {
      field: 'paidamount',
      headerName: 'Paid Amount',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>Rs {params.row?.payment?.paidamount ? params.row?.payment?.paidamount : 0}</p>
        </div>
      )
    },
    {
      field: 'balanceAmount',
      headerName: 'Balance Amount',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>Rs {params.row?.payment?.balanceAmt ? params.row?.payment?.balanceAmt : 0}</p>
        </div>
      )
    },
    {
      field: 'totalAmount',
      headerName: 'Total Amount',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>Rs {params.row?.payment?.totalamount ? params.row?.payment?.totalamount : 0}</p>
        </div>
      )
    },
    {
      field: 'paymentType',
      headerName: 'Payment Method',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.payment?.paymentType}</p>
        </div>
      )
    },
    {
      field: 'bookingStatus',
      headerName: 'Payment Status',
      width: 150,
      renderCell: (params) => getStatusContent(params.row?.bookingStatus)
    },


  ];

  const rows = [
    {
      id: 1,

    }
  ];

  return (
    <div style={{ minheight: '100vh', width: '100%', marginTop: '8px' }}>
      <DataGrid
        rows={DataWithID(UpcomingBookingData)}
        columns={columns}
        density="comfortable"
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        components={{ Toolbar: CustomToolbar }}
      />
    </div>
  )
}

export default UpComingBookingTable