import axios from 'axios';
import * as constant from '../../Constants/VendorConstant/VendorGetAllConstants';
import { API_URL } from '../../../config';
import instance from '../../../utils/_utils';

export const GetAllVendors = (requiredFields) => {
  return async (dispatch) => {
    dispatch({ type: constant.GETALL_VENDOR_API_LOADING });
    try {
      const parmas = requiredFields ? `?${new URLSearchParams({ fields: requiredFields }).toString()}` : '';
      const response = await instance.get(`/api/vendor/getall${parmas}`);
      if (response.status === 200) {
        dispatch({ type: constant.GETALL_VENDOR_API_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: constant.GETALL_VENDOR_API_ERROR });
    }
  };
};
