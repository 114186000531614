import React from 'react'
import PaymentTable from '../components/Tables/PaymentTable'

const Payment = () => {
  return (
    <div>
      <PaymentTable />
    </div>
  )
}

export default Payment