import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from '../../../config';
import * as constant from '../../Constants/AuthConstants/VendorConstants';
import instance from '../../../utils/_utils';

export const showSuccessAlert = (message, navigateToRoute) => {
  Swal.fire({
    icon: 'success',
    title: message,
    // timer: 1500,
    showConfirmButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      // Navigate to the desired route if the user clicks "Ok"
      if (navigateToRoute) {
        window.location.href = navigateToRoute;
      }
    }
  });
};

export const showErrorAlert = (message) => {
  Swal.fire('Error!', message, 'error');
};

export const GetVendorSignUp = (formdata, otpdata) => {
  return async (dispatch) => {
    dispatch({ type: constant.VENDOR_SIGNUP_API_LOADING });
    try {
      const otpQuery = new URLSearchParams(otpdata);
      const otpVerified = await instance.get(`/verify/verify-otp?${otpQuery}`);
      if (otpVerified.status === 200) {
        const response = await instance.post(`/api/vendor/signup`, formdata);
        if (response.status === 200) {
          dispatch({ type: constant.VENDOR_SIGNUP_API_SUCCESS, payload: response.data });
          sessionStorage.setItem('user', JSON.stringify(response.data.data));
          showSuccessAlert('Registered Successfully', '/dashboard/app'); // Redirect to '/dashboard/app'
        } else {
          showErrorAlert(response.data.message);
        }
      } else {
        showErrorAlert(otpVerified.data.message);
      }
    } catch (error) {
      dispatch({ type: constant.VENDOR_SIGNUP_API_ERROR });
      showErrorAlert('Registration Failed');
    }
  };
};

export const GetVendorLogIn = (formdata, role) => {
  return async (dispatch) => {
    dispatch({ type: constant.VENDOR_LOGIN_API_LOADING });
    try {
      let response;
      if (role === 'admin') {
        response = await instance.post(`/api/admin/login`, formdata);
      } else if (role === 'vendor') {
        response = await instance.post(`/api/vendor/login`, formdata);
      }
      if (response.status === 200) {
        dispatch({ type: constant.VENDOR_LOGIN_API_SUCCESS, payload: response.data });
        window.localStorage.setItem('user', JSON.stringify(response.data.token));
        sessionStorage.setItem('user', JSON.stringify(response.data.data));
        showSuccessAlert('Logged In Successfully', '/dashboard/app'); // Redirect to '/dashboard/app'
      }
    } catch (error) {
      dispatch({ type: constant.VENDOR_LOGIN_API_ERROR, payload: error.message });
      // You can also log the error or display it in an error message
      console.error('Login API Error:', error);
      showErrorAlert('Login Failed');
    }
  };
};

export default { GetVendorSignUp, GetVendorLogIn };
