import { Backdrop, Box, Button, Fade, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import { UseStateManager } from '../Context/StateManageContext';
import VendorHotel from '../components/Tables/VendorHotel';
import ZeroHotelForm from '../components/Forms/ZeroHotelForm';
import Bread from '../components/Bread/Bread';
import GlobalModel from '../components/GlobalModal/GlobalModel';
import { API_URL } from '../config';
import { AdminAddCountAction } from '../Store/Actions/HotelActions/HotelCurdAction';

const AddHotel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const AdminAddCount = useSelector((state) => state.AdminAddCountReducer?.data);
  const adminCount = AdminAddCount?.[0]?.count
  const vendorCount = AdminAddCount?.[1]?.count
  useEffect(() => {
    dispatch(AdminAddCountAction())
  }, [])
  const { currentUser, isKyc } = UseStateManager();

  const [firstHotelAdded, setFirstHotelAdded] = useState(true);
  const [loder, setLoader] = useState(true)
  const [count, setCount] = useState([])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: '800px',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isShaking, setIsShaking] = useState(true);
  useEffect(() => {
    if (currentUser?.hotel?.length > 0) {
      setIsShaking(false);
    } else {
      setIsShaking(true);
    }
  }, [currentUser]);


  const getVendorAnalytics = async () => {
    try {
      const response = await fetch(`${API_URL}/analytics/partner/hotel/info/${currentUser._id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      setCount(result.data);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching typing result:", error);
    }
  };

  useEffect(() => {
    getVendorAnalytics()
  }, [currentUser])

  return (
    <div>
      <Bread tabName={'Manage Hotel'} />

      {currentUser?.role === 'admin' || isKyc?.isVerified === 'approved' ? (
        <>
          {/* zero hotel form with global modal */}
          <GlobalModel
            open={open}
            content={
              <Fade in={open}>
                <Box sx={style}>
                  <ZeroHotelForm handleClose={handleClose} />
                </Box>
              </Fade>
            }
          />
          {/* Check if the user is logged in and has an 'admin' role */}
          {!currentUser ? (
            '' // Render nothing if user is not logged in
          ) : currentUser.role === 'admin' ? (
            <Grid container spacing={3}>
              {/* Add Own Hotel */}
              <Grid item xs={12} sm={6} md={6}>
                <AppWidgetSummary
                  title="Add Own Hotel"
                  total={adminCount || '0'}
                  color="info"
                  icon={'material-symbols:add'}
                // onClick={handleOpen}
                />
              </Grid>

              {/* Add Vendor Hotel */}
              <Grid item xs={12} sm={6} md={6}>
                <AppWidgetSummary
                  title="Add Partner Hotel"
                  total={vendorCount || '0'}
                  color="warning"
                  icon={'tabler:building-hospital'}
                // onClick={handleOpen}
                />
              </Grid>

              {/* VendorHotel Component */}
              <Grid item xs={12} sm={12} md={12}>
                <VendorHotel handleOpen={handleOpen} />
              </Grid>
            </Grid>
          ) : (
            <>
              {/* Check if the user has added their first hotel */}
              {firstHotelAdded ? (
                <Grid container spacing={3}>
                  {/* Add Hotel */}
                  {/* <Grid className={`shake-element ${isShaking ? 'shake' : ''}`} item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Click here to add a hotel"
                      total={currentUser.hotels ? currentUser.hotels.length : 0}
                      onClick={handleOpen}
                      color="warning"
                      icon={'material-symbols:add'}
                    />
                  </Grid> */}

                  {/* My Hotels */}
                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      title="My Hotels"
                      total={count?.totalHotels === 0 ? '0' : count?.totalHotels}
                      color="info"
                      icon={'tabler:building'}
                    />
                  </Grid>

                  {/* Approved Hotel */}
                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      title="Approved Hotel"
                      total={count?.totalApprovedHotels === 0 ? '0' : count?.totalApprovedHotels}
                      color="success"
                      icon={'tabler:discount-check-filled'}
                    />
                  </Grid>

                  {/* Pending / Rejected */}
                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      title="In-Active Hotels"
                      total={count?.totalInactiveHotel === 0 ? '0' : count?.totalInactiveHotel}
                      color="primary"
                      icon={'solar:bill-cross-bold'}
                    />
                  </Grid>


                  {/* VendorHotel Component */}
                  <Grid item xs={12} sm={12} md={12}>
                    <VendorHotel handleOpen={handleOpen} />
                  </Grid>
                </Grid>
              ) : (
                <ZeroHotelForm />
              )}
            </>
          )}
        </>
      ) : (
        <Grid container spacing={1}>
          <Grid xs={12} item textAlign={'center'}>
            <Typography variant="h5">First Complete you profile using below button</Typography>
            <Button onClick={() => navigate('/dashboard/profile')} variant="contained" color="error">
              Verify
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AddHotel;
