import { API_URL } from "../config";

const getRoomTitleById = async (roomId) => {
  try {
    const response = await fetch(`${API_URL}/roomtype/get?id=${roomId}`);

    if (!response.ok) {
      throw new Error(`Failed to fetch room details. Status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');

    if (!contentType || !contentType.includes('application/json')) {
      throw new Error('Invalid response format. Expected JSON.');
    }

    const data = await response.json();
    console.log(data)
    const titles = data.data.map((item) => item.title);

    return titles;
  } catch (error) {
    console.error("Error fetching room details:", error);
    throw error;
  }
};

export { getRoomTitleById };
