import * as constant from "../../Constants/AuthConstants/VendorConstants"

const initialState = {
    isLoading: false,
    isSuccess: null,
    data: [],
    isError: false
}


const GetVendorLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.VENDOR_LOGIN_API_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case constant.VENDOR_LOGIN_API_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isSuccess: true,
                isLoading: false
            }
        case constant.VENDOR_LOGIN_API_ERROR:
            return {
                ...state,
                isError: true,
                isSuccess: false,
                isLoading: false
            }

        default:
            return state;
    }
}

export default GetVendorLoginReducer