export const GET_ALL_FACILITIES_LOADING = "GET_ALL_FACILITIES_LOADING";
export const GET_ALL_FACILITIES_SUCCESS = 'GET_ALL_FACILITIES_SUCCESS';
export const GET_ALL_FACILITIES_ERROR = 'GET_ALL_FACILITIES_ERROR';

export const ADD_FACILITIES_LOADING = "ADD_FACILITIES_LOADING";
export const ADD_FACILITIES_SUCCESS = 'ADD_FACILITIES_SUCCESS';
export const ADD_FACILITIES_ERROR = 'ADD_FACILITIES_ERROR';

export const REMOVE_FACILITIES_LOADING = "REMOVE_FACILITIES_LOADING";
export const REMOVE_FACILITIES_SUCCESS = 'REMOVE_FACILITIES_SUCCESS';
export const REMOVE_FACILITIES_ERROR = 'REMOVE_FACILITIES_ERROR';

export const REMOVE_ALL_FACILITIES_LOADING = "REMOVE_ALL_FACILITIES_LOADING";
export const REMOVE_ALL_FACILITIES_SUCCESS = 'REMOVE_ALL_FACILITIES_SUCCESS';
export const REMOVE_ALL_FACILITIES_ERROR = 'REMOVE_ALL_FACILITIES_ERROR';