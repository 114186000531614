import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


export default function HotelVerificationTable() {



    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => (
                <div>
                    <p>{params.row.name}</p>
                </div>
            )
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 180,
            renderCell: (params) => (
                <div>
                    <b>{params.row.code}</b>
                </div>
            )
        },
        {
            field: 'applicable',
            headerName: 'Applicable',
            width: 120
        },
        {
            field: 'discount',
            headerName: 'Discount',
            width: 100
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 100
        },
        {
            field: 'expiry',
            headerName: 'Expiry',
            width: 100
        },
        {
            field: 'limit',
            headerName: 'Limit',
            width: 100
        },
        {
            field: 'claimed',
            headerName: 'Claimed',
            width: 100
        },

    ];

    const rows = [
        {
            id: 1,
            name: 'Black Friday',
            code: 'BLACKFRIDAY50',
            couponStatus: true,
            applicable: 'All hotels',
            discount: '50%',
            claimed: 299,
            startDate: '22-10-2023',
            expiry: '25-10-2023',
            limit: 500,
        },
        {
            id: 2,
            name: 'Cyber Monday',
            code: 'CYBERMONDAY20',
            couponStatus: true,
            applicable: 'Delux Rooms',
            discount: '20%',
            claimed: 299,
            startDate: '22-10-2023',
            expiry: '25-10-2023',
            limit: 500,
        },
        {
            id: 3,
            name: 'Welcome',
            code: 'WELCOME80',
            couponStatus: true,
            applicable: 'All hotels',
            discount: '80%',
            claimed: 299,
            startDate: '22-10-2023',
            expiry: '25-10-2023',
            limit: 500,
        },
        {
            id: 4,
            name: 'Vip',
            code: 'VIP50',
            couponStatus: true,
            applicable: 'Luxury Rooms',
            discount: '50%',
            claimed: 299,
            startDate: '22-10-2023',
            expiry: '25-10-2023',
            limit: 500,
        },
        {
            id: 5,
            name: 'Diwali',
            code: 'DIWALI20',
            couponStatus: false,
            applicable: 'All hotels',
            discount: '20%',
            claimed: 299,
            startDate: '22-10-2023',
            expiry: '25-10-2023',
            limit: 500,
        },
        {
            id: 6,
            name: 'Black Friday',
            code: 'BLACKFRIDAY50',
            couponStatus: true,
            applicable: 'All hotels',
            discount: '50%',
            claimed: 299,
            startDate: '22-10-2023',
            expiry: '25-10-2023',
            limit: 500,
        }
    ];

    return (
        <div style={{ minheight: '100vh', width: '100%', marginTop: '8px' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                density="comfortable" // Set default density to "comfortable"
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </div>
    );
}