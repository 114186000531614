export const GET_ALL_SLIDER_API_LOADING = 'GET_ALL_SLIDER_API_LOADING'
export const GET_ALL_SLIDER_API_SUCCESS = 'GET_ALL_SLIDER_API_SUCCESS'
export const GET_ALL_SLIDER_API_ERROR = 'GET_ALL_SLIDER_API_ERROR'

export const ADD_SLIDER_API_LOADING = 'ADD_SLIDER_API_LOADING'
export const ADD_SLIDER_API_SUCCESS = 'ADD_SLIDER_API_SUCCESS'
export const ADD_SLIDER_API_ERROR = 'ADD_SLIDER_API_ERROR'

export const DELETE_SLIDER_API_LOADING = 'DELETE_SLIDER_API_LOADING'
export const DELETE_SLIDER_API_SUCCESS = 'DELETE_SLIDER_API_SUCCESS'
export const DELETE_SLIDER_API_ERROR = 'DELETE_SLIDER_API_ERROR'