import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUserHotelData } from '../Store/Actions/HotelActions/HotelCurdAction';
import { UseStateManager } from '../Context/StateManageContext';
import { GetBookingActionByHotelId } from '../Store/Actions/BookingActions/GetBookingActions';
import BookingList from '../components/Tables/BookingList';

const HotelWiseBooking = () => {

  const dispatch = useDispatch();
  const { currentUser } = UseStateManager();

  const [showBooking, setShowBooking] = useState(false)
  const [bookingHotelId, setBookingHotelId] = useState("")

  const handleShowBooking = (hotelId) => {
    setShowBooking(!showBooking);
    setBookingHotelId(hotelId)
  };

  useEffect(() => {
    dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
  }, [currentUser]);

  useEffect(() => {
    dispatch(GetBookingActionByHotelId(bookingHotelId));
  }, [showBooking]);

  const HotelsList = useSelector((state) => state.GetAllHotelReducer);
  const BookingData = useSelector((state) => state.BookingByIdReducers);

  return (
    <Box>
      {
        showBooking ?
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Button variant='contained' onClick={() => setShowBooking(!showBooking)} >Back</Button>
              <Card sx={{ mt: 1 }}>
                <Box sx={{ position: 'relative' }}>
                  <CardMedia
                    sx={{ height: 300, borderRadius: '12px' }}
                    image={BookingData?.data[0]?.hotelCoverImg}
                    title={BookingData?.data[0]?.hotelName}
                  />
                  <Typography sx={{ position: 'absolute', bottom: '10px', left: '10px' }} variant='h3' color={'#ffffff'}>{BookingData?.data[0]?.hotelName}</Typography>
                </Box>
                <BookingList Data={BookingData?.data[0]?.bookings} />
              </Card>
            </Grid>
          </Grid> :
          <Grid container spacing={2}>
            {HotelsList?.data.map((item, index) => (
              <Grid key={index} item md={4}>
                <Card>
                  <CardMedia
                    sx={{ height: 240, borderRadius: '12px' }}
                    image={item?.hotelCoverImg}
                    title={item?.hotelName}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {item?.hotelName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item?.address}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      {item?.hotelEmail}
                    </Typography>
                    <Typography variant="body2" color="error">
                      {item?.hotelMobileNo}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {item?.bookings.length === 0 ? <Button variant='text' color='error' size="small">No Booking</Button> : <Button onClick={() => handleShowBooking(item?._id)} variant='contained' size="small">Check Bookings</Button>}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
      }
    </Box>
  )
}

export default HotelWiseBooking