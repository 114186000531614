import * as constant from '../../Constants/adminConstants/allRoomType';


const initialState = {
    isLoading: false,
    isError: false,
    data: []
}


const AllRoomTypeReducers = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_ALL_ROOM_TYPE_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GET_ALL_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.GET_ALL_ROOM_TYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}



export default AllRoomTypeReducers 