import { Button, Card, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UseStateManager } from '../Context/StateManageContext'

const VendorNotifications = () => {

    const navigate = useNavigate()

    const { currentUser } = UseStateManager();

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>You</b> just added new hotel waiting for approval</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/add')} sx={{ mr: 2 }} variant='contained' color='primary' > Check status</Button>
                                <Button variant='outlined' color='error'> View Detail </Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>Abhishek Sharma</b> completed room payment through Credit card</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/booking')} sx={{ mr: 2 }} variant='contained' color='primary' > View Order</Button>
                                <Button variant='outlined' color='error'> Cancel</Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>Abhishek Sharma</b> booked premium room waiting for Booking status</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/booking')} sx={{ mr: 2 }} variant='contained' color='primary' > View Order</Button>
                                <Button variant='outlined' color='error'> Cancel</Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>You</b> just added new offer all premimum rooms 20% off</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/add')} sx={{ mr: 2 }} variant='contained' color='primary' > Check status</Button>
                                <Button variant='outlined' color='error'> View Detail </Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>Abhishek Sharma</b> booked premium room waiting for Booking status</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/booking')} sx={{ mr: 2 }} variant='contained' color='primary' > View Order</Button>
                                <Button variant='outlined' color='error'> Cancel</Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>Raj K</b> completed room payment through UPI</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/booking')} sx={{ mr: 2 }} variant='contained' color='primary' > View Order</Button>
                                <Button variant='outlined' color='error'> Cancel</Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>Raj K</b> booked Delux room waiting for Booking status</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/booking')} sx={{ mr: 2 }} variant='contained' color='primary' > View Order</Button>
                                <Button variant='outlined' color='error'> Cancel</Button>
                            </div>
                        </div>
                    </Card>
                </Grid>


                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>Hotelio</b> applied coupons <b>BLACKFRIDAY50</b> 50% OFF all rooms</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/booking')} sx={{ mr: 2 }} variant='contained' color='primary' > View Order</Button>
                                <Button variant='outlined' color='error'> Cancel</Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>Welcome to Hotelio</b>, Hotelio Approved your Hotel</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/add')} sx={{ mr: 2 }} variant='contained' color='primary' > Check status</Button>
                                <Button variant='outlined' color='error'> View Detail </Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}><b>You</b> just added your first hotel waiting for approval</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/add')} sx={{ mr: 2 }} variant='contained' color='primary' > Check status</Button>
                                <Button variant='outlined' color='error'> View Detail </Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}>Begin your journey by listing your hotel on <b>Hotelio</b>.</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/add')} sx={{ mr: 2 }} variant='contained' color='primary' > Add Hotel</Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div style={{ display: 'flex', padding: '14px', alignItems: 'center' }}>
                            <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                            <div style={{ marginLeft: '5px', width: '50%' }}>
                                <p style={{ margin: '10px 0px' }}>First, complete your profile on <b>Hotelio</b>.</p>
                                <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>about 1 min ago</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%' }}>
                                <Button onClick={() => navigate('/dashboard/profile')} sx={{ mr: 2 }} variant='contained' color='primary' > Setup Profile</Button>
                            </div>
                        </div>
                    </Card>
                </Grid>
            </Grid>

            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        asd
                    </Grid>
                </Grid>
            </div>
        </div >
    )
}

export default VendorNotifications