import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import * as constant from '../../Constants/UploadConstants/UploadConstants';
import * as config from '../../../config';
import instance from '../../../utils/_utils';

const s3Client = new S3Client({
  region: 'ap-south-1',
  credentials: {
    accessKeyId: config.ACCESSKEY,
    secretAccessKey: config.SECRETACCESSKEY,
  },
});

async function GetObjectUrl(key) {
  const CreateObjUrl = new GetObjectCommand({
    Bucket: 'hotelio-images',
    Key: key,
  });
  const signedUrl = await getSignedUrl(s3Client, CreateObjUrl);
  return signedUrl;
}

export const getImageSignedUrl = async (key) => {
  try {
    const queryUrl = new URL(`/util/s3/signedurl`);
    queryUrl.searchParams.set('key', key);
    const response = await instance.get(queryUrl.toString());

    if (response.status === 200) {
      return response.data.url;
    }

    throw new Error('Failed to get signed URL');
  } catch (error) {
    // Handle errors, e.g., log or rethrow as needed
    console.error('Error while fetching signed URL:', error);
    throw error;
  }
};

// get Documents Upload

// export const GetFileUploaded = (e, userId) => {
//     return async (dispatch) => {
//         dispatch({ type: constant.UPLOAD_API_LOADING })
//         const file = e.target.files[0]

//         // create formdata
//         const fd = new FormData()
//         fd.append("myfile", file)

//         try {
//             const isResponse = await axios.post(`${API_URL}/util/uploadfile/${userId}`, fd)
//             if (isResponse.status === 200) {
//                 dispatch({ type: constant.UPLOAD_API_SUCCESS, payload: isResponse.data, field: e.target.name })
//                 Swal.fire({
//                     title: 'Image Uploaded!',
//                     imageUrl: `${API_URL}/uploads/${isResponse.data.fileName}`,
//                     imageWidth: 400,
//                     imageHeight: 200,
//                     imageAlt: 'Uploaded',
//                 })
//             }
//         } catch (error) {
//             dispatch({ type: constant.UPLOAD_API_ERROR })
//             Swal.fire({
//                 title: "Image Upload Failed",
//                 text: "Image upload failed . Please try Again",
//                 icon: "error"
//             })
//         }
//     }
// }

export const GetFileUploaded = (e, currentUserId) => {
  return async (dispatch) => {
    dispatch({ type: constant.UPLOAD_API_LOADING });

    const fileName = e.target.files[0];

    const queryParmas = new URLSearchParams({ id: currentUserId, fileName: fileName.name });

    const uploadUrl = await instance.get(`/util/s3/upload?${queryParmas}`);
    if (uploadUrl.status === 200) {
      const uploadFire = await axios.put(uploadUrl.data.url, fileName);
      if (uploadFire.status === 200) {
        const image = await GetObjectUrl(uploadUrl.data.UploadedPath);
        const awsString = `https://hotelio-images.s3.amazonaws.com/${uploadUrl.data.UploadedPath}`;
        dispatch({ type: constant.UPLOAD_API_SUCCESS, payload: uploadUrl, field: { [e.target.name]: awsString } });
        Swal.fire({
          title: 'Image Uploaded!',
          imageUrl: awsString,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Uploaded',
        });
      } else {
        console.log('s3 issue to upload image');
      }
    } else {
      dispatch({ type: constant.UPLOAD_API_ERROR });
      Swal.fire({
        title: 'Image Upload Failed',
        text: 'Image upload failed . Please try Again',
        icon: 'error',
      });
    }
  };
};
