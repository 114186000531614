import instance from '../../../utils/_utils';
import * as constant from '../../Constants/SliderConstants/SliderConstants';

export const GetAllSliderAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_ALL_SLIDER_API_LOADING });
            const response = await instance.get(`/slider/get-in-slider`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_ALL_SLIDER_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_ALL_SLIDER_API_ERROR });
        }
    };
};


export const AddSliderImage = (sliderImage) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.ADD_SLIDER_API_LOADING });
            const response = await instance.get(`/slider/add-in-slider?imgurl=${sliderImage}`);
            if (response.status === 200) {
                dispatch({ type: constant.ADD_SLIDER_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.ADD_SLIDER_API_ERROR });
        }
    };
}

export const DeleteSliderImage = (sliderId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.DELETE_SLIDER_API_LOADING });
            const response = await instance.get(`/slider/delete-in-slider/${sliderId}`);
            if (response.status === 200) {
                dispatch({ type: constant.DELETE_SLIDER_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.DELETE_SLIDER_API_ERROR });
        }
    };
}