import axios from 'axios';

const { createContext, useContext, useState, useEffect } = require('react');

const useGoogleFunc = createContext();

const GoogleProvider = ({ children }) => {
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [address, setAddress] = useState('');
  const [addressData, setAddressData] = useState(null);

  // Google Place get with longittude and latitude
  const GOOGLE_API_KEY = 'AIzaSyD_kgE_S3Nwf1IAamPa6D6ZyyazleBTrhI';

  const getPlaceAddress = async () => {
    let latitude;
    let longitude;

    // Check if user is allowed access to their location
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      } catch (error) {
        console.error('Error getting user location:', error);
        return;
      }
    } else {
      navigator.geolocation.getCurrentPosition((place) => {
        latitude = place.coords.latitude;
        longitude = place.coords.longitude;
      });
    }
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
      );

      if (response.data.status === 'OK' && response.data.results.length > 0) {
        setAddress(response.data.results[0].formatted_address);
        // setAddressData(response.data);
        console.log(response.data);
        const locationDetails = await getPlaceDetails(response.data);
        setAddressData(locationDetails);
        console.log(locationDetails);
      } else {
        console.error('No results found for the given coordinates.');
        setAddress(null);
      }
    } catch (error) {
      console.error('Error performing reverse geocoding:', error);
      setAddress(null); // Reset address on error
    }
  };

  async function getAddressComponent(type) {
    const component = await address?.find((component) => component.types.includes(type));

    return component ? component.long_name : '';
  }

  const getPlaceDetails = async (results) => {
    if (results && results?.length > 0) {
      const result = results[0];
      const lastIdx = result?.address_components.length - 1;

      const zipcode = result?.address_components[lastIdx].long_name;
      const country = result?.address_components[lastIdx - 1].long_name;
      const state = result?.address_components[lastIdx - 2].long_name;
      const city = result?.address_components[lastIdx - 3].long_name;

      const locality = `${result?.address_components[lastIdx - 5].long_name}, ${
        result?.address_components[lastIdx - 4].long_name
      }`;
      const address = result?.formatted_address;

      return { zipcode, country, state, city, locality, address };
      // Now you can use the variables zipcode, country, state, city, locality, and fullAddress
    }

    console.error('No results found in addressData');
    return null; // Return null when there are no results
  };

  useEffect(() => {
    const location = JSON.parse(window.localStorage.getItem('location'));
    if (location) {
      setLatitude(location.latitude);
      setLongitude(location.longitude);
    }
  }, []);

  return (
    <useGoogleFunc.Provider
      value={{ getPlaceAddress, address, getAddressComponent, getPlaceDetails, addressData, latitude, longitude }}
    >
      {children}
    </useGoogleFunc.Provider>
  );
};

const useGoogle = () => {
  return useContext(useGoogleFunc);
};

export { GoogleProvider, useGoogle };
