import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes, { object } from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Swal from 'sweetalert2';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { UseStateManager } from '../../Context/StateManageContext';
import { GetFileUploaded } from '../../Store/Actions/ImageUploadActions/UploadActions';
import { useUtilsContext } from '../../Context/UtilsContext';
import { GetVendorDetailsSubmit } from '../../Store/Actions/AuthActions/VendorVerificationAction';
import WaitLoader from '../../utils/WaitLoader';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon({ active, completed, className, icon }) {
  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ['Enter Verification Detail', 'Upload Your Files', 'Verify & Confirm'];

export default function VendorVerificationForm() {
  // current user
  const { currentUser, setCurrentUser, Loader, setLoader } = UseStateManager();

  // dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // image upload reducer
  const UploadedImage = useSelector((state) => state.UploadReducer);

  // SendVerification code
  const { sendEmailVerify, EmailResponse, setEmailResponse } = useUtilsContext();

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (UploadedImage.isSuccess === true) {
      setFormData({ ...formData, ...UploadedImage.fileName });
    }
  }, [UploadedImage]);

  // Handle send the verification code to the user mail
  const HandleVerificationAndNext = () => {
    setLoader(true);
    if (formData.name !== undefined && formData.email !== undefined) {
      const data = { email: formData.email, name: formData.name, format: 'otp' };

      sendEmailVerify(data)
        .then((result) => {
          setLoader(false);
          handleNext();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire({
        title: 'Please Fill All Credentials',
        icon: 'error',
      });
    }
  };

  // reducer final submit
  const FinalSubmitResult = useSelector((state) => state.GetVendorVerifyReducer);

  // Submit the final form
  const finalSubmitFormdata = {
    name: formData.name,
    email: formData.email,
    aadharNo: formData.aadhaarNumber,
    panNo: formData.panCardNumber,
    panImg: formData.pan,
    aadharImg: [formData.frontAadhar, formData.BackAadhaar],
  };

  const FinalFormSubmit = (formdata) => {
    setLoader(true);
    dispatch(
      GetVendorDetailsSubmit(formdata, EmailResponse.data, formData.email, formData.verificationCode, currentUser._id)
    ).then(() => {
      // if (FinalSubmitResult.isSuccess === true) {
      setLoader(false);
      setActiveStep(activeStep + 1);
    });
  };

  // get File Upload
  const HandleGetFileUploaded = (e, id) => {
    setLoader(true);
    dispatch(GetFileUploaded(e, id)).then(() => {
      setLoader(false);
    });
  };

  useEffect(() => {
    if (currentUser) {
      const data = { email: currentUser.email, name: currentUser.name };
      setFormData({ ...formData, ...data });
    }
  }, [currentUser]);

  const getFormFields = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              id="name"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
              fullWidth
              required
              label="Name"
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              required
              value={formData.email || ''}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              id="aadhaarNumber"
              name="aadhaarNumber"
              label="Aadhaar Number"
              required
              value={formData.aadhaarNumber || ''}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              id="panCardNumber"
              name="panCardNumber"
              label="PAN Card Number"
              required
              value={formData.panCardNumber || ''}
              onChange={handleChange}
              fullWidth
            />
          </>
        );
      case 1:
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label htmlFor="file1">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="file1"
                  type="file"
                  required
                  onChange={(e) => HandleGetFileUploaded(e, currentUser._id)}
                  name="pan"
                />
                <Button variant="contained" component="span">
                  Upload Pan Card
                </Button>
                <p>
                  <b> Please upload only jpeg, jpg, png files.</b>
                </p>
              </label>
              {formData.file1 && <p>File 1: {formData.file1.name}</p>}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label htmlFor="file2">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="file2"
                  required
                  type="file"
                  onChange={(e) => HandleGetFileUploaded(e, currentUser._id)}
                  name="frontAadhar"
                />
                <Button variant="contained" component="span">
                  Upload Front side of Aadhar card
                </Button>
                <p>
                  <b> Please upload only jpeg, jpg, png files.</b>
                </p>
              </label>
              {formData.file2 && <p>Aadhar Front side {formData.file2.name}</p>}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label htmlFor="file3">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="file3"
                  required
                  type="file"
                  onChange={(e) => HandleGetFileUploaded(e, currentUser._id)}
                  name="BackAadhaar"
                />
                <Button variant="contained" component="span">
                  Upload Back side of Aadhar card
                </Button>
                <p>
                  <b> Please upload only jpeg, jpg, png files.</b>
                </p>
              </label>
              {formData.file3 && <p>Aadhar Back side: {formData.file3.name}</p>}
            </div>
          </>
        );

      case 2:
        return (
          <>
            <h4 style={{ textAlign: 'center' }}>We sent a Verification Code to your email</h4>
            <TextField
              id="verificationCode"
              name="verificationCode"
              label="Verification Code"
              value={formData.verificationCode || ''}
              onChange={handleChange}
              fullWidth
            />
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (activeStep === steps.length) {
      setTimeout(() => {
        navigate('/dashboard/app');
        window.location.reload();
      }, 5000);
    }
  }, [activeStep]);

  return (
    <>
      {activeStep === steps.length ? (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <CheckCircleIcon color="success" sx={{ fontSize: '10rem' }} />
          You have been verified as a vendor. After a second, you will be automatically redirected to your dashboard.
        </div>
      ) : (
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <WaitLoader Loading={Loader} />
          <form onSubmit={handleNext}>
            <Stack spacing={2}>
              {getFormFields(activeStep)}
              <Stack direction="row" justifyContent="space-between">
                <Button variant="contained" disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                {activeStep === 0 ? (
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => {
                      handleNext();
                    }}
                    color="primary"
                  >
                    Next
                  </Button>
                ) : activeStep === 1 ? (
                  <Button
                    variant="contained"
                    type="button"
                    disabled={Object.keys(formData).length !== 7}
                    onClick={() => {
                      HandleVerificationAndNext();
                    }}
                    color="primary"
                  >
                    Verify
                  </Button>
                ) : activeStep === 2 ? (
                  <Button
                    variant="contained"
                    type="button"
                    disabled={formData?.verificationCode === ''}
                    onClick={() => FinalFormSubmit(finalSubmitFormdata)}
                    color="primary"
                  >
                    Finish
                  </Button>
                ) : null}
              </Stack>
            </Stack>
          </form>
        </Stack>
      )}
    </>
  );
}
