import axios from "axios"
import { API_URL } from "../../../config"
import * as constant from "../../Constants/VendorConstant/VendorGetAllConstants"
import instance from "../../../utils/_utils"





export const GetVendorByIdAction = (id) => {
    return async (dispatch) => {
        dispatch({ type: constant.GET_SINGLE_VENDOR_API_LOADING })
        try {
            const response = await instance.get(`/hotel/hoteldetails/${id}`)
            if (response.status === 200) {
                dispatch({ type: constant.GET_SINGLE_VENDOR_API_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.GET_SINGLE_VENDOR_API_ERROR })
        }
    }
}