import instance from '../../../utils/_utils';
import * as constant from '../../Constants/ReviewRatingConstants/ReviewRatingConstants';

export const GetAllReviewRatingsByIdAction = (vendorid) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_REVIEW_API_LOADING });
            const response = await instance.get(`/reviews/partner/all-hotels/${vendorid}`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_REVIEW_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_REVIEW_API_ERROR });
        }
    };
};

export const UpdateReviewStatusAction = (reviewId, status) => {
    console.log(status)
    return async (dispatch) => {
        try {
            dispatch({ type: constant.UPDATE_REVIEW_STATUS_API_LOADING });
            const response = await instance.get(`/reviews/timeline/${reviewId}/${status}`);
            if (response.status === 200) {
                dispatch({ type: constant.UPDATE_REVIEW_STATUS_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.UPDATE_REVIEW_STATUS_API_ERROR });
        }
    };
};


export const GetAllReviewRatingsByHotelAction = (hotelId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GETHOTEL_REVIEW_API_LOADING });
            const response = await instance.get(`/reviews/get/hotel/${hotelId}`);
            if (response.status === 200) {
                dispatch({ type: constant.GETHOTEL_REVIEW_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GETHOTEL_REVIEW_API_ERROR });
        }
    };
};


export const GetAllReviewRatingsAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GETALL_REVIEW_API_LOADING });
            const response = await instance.get(`/reviews/getall`);
            if (response.status === 200) {
                dispatch({ type: constant.GETALL_REVIEW_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GETALL_REVIEW_API_ERROR });
        }
    };
};

export const DeleteReviewRatingsAction = (ratingReviewId) => {
    console.log(ratingReviewId)
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_REVIEW_API_LOADING });
            const response = await instance.delete(`/reviews/delete/${ratingReviewId}`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_REVIEW_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_REVIEW_API_ERROR });
        }
    };
};