import * as constant from "../../Constants/AuthConstants/VendorVerificationConstant";

const initialValues = {
    isLoading: false,
    isSuccess: false,
    data: null,
    isError: false
}

const GetVendorVerifyReducer = (state = initialValues, action) => {
    switch (action.type) {
        case constant.VENDOR_VERIFY_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.VENDOR_VERIFY_API_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                data: action.payload
            }
        case constant.VENDOR_VERIFY_API_ERROR:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSuccess: false
            }

        default:
            return {
                ...state
            }
    }
}



export default GetVendorVerifyReducer;