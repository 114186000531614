import instance from '../../utils/_utils';
import * as constant from '../Constants/faqconstants';

// get the all faqs
export const GetFaqByUser = (field) => {
  return async (dispatch) => {
    const fields = new URLSearchParams(field).toString();
    dispatch({ type: constant.FAQ_BYFIELD_API_LOADING });
    try {
      const response = await instance.get(`/faq/getbyuser?${fields}`);
      if (response.status === 200) {
        dispatch({ type: constant.FAQ_BYFIELD_API_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: constant.FAQ_BYFIELD_API_ERROR });
    }
  };
};

export const CreateFaqAction = (fromdata) => {
  return async (dispatch) => {
    console.log(fromdata);
    dispatch({ type: constant.FAQ_CREATE_API_LOADING });
    try {
      const response = await instance.post('/faq/create', fromdata);
      if (response.status === 200) {
        dispatch({ type: constant.FAQ_CREATE_API_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: constant.FAQ_CREATE_API_ERROR });
    }
  };
};

export const UpdateFaqAction = (id, fromData) => {
  return async (dispatch) => {
    dispatch({ type: constant.FAQ_UPDATE_API_LOADING });
    try {
      console.log(id, fromData);
      const response = await instance.patch(`/faq/update/${id}`, fromData);
      if (response.status === 200) {
        dispatch({ type: constant.FAQ_BYFIELD_API_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: constant.FAQ_UPDATE_API_ERROR });
    }
  };
};
