import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import { UseStateManager } from '../Context/StateManageContext';
import VendorHotel from '../components/Tables/VendorHotel';
import Bread from '../components/Bread/Bread';
import WaitLoader from '../utils/WaitLoader';
import { GetUserHotelData } from '../Store/Actions/HotelActions/HotelCurdAction';
import { API_URL } from '../config';

const HotelList = () => {
  const navigate = useNavigate();

  const { currentUser, isKyc } = UseStateManager();

  const HotelsList = useSelector((state) => state.GetAllHotelReducer);

  const dispatch = useDispatch();

  const [loder, setLoader] = useState(true)
  const [count, setCount] = useState([])

  useEffect(() => {
    dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
  }, [currentUser]);

  const getVendorAnalytics = async () => {
    try {
      setLoader(true); // Set loading indicator to true before making the API call

      const apiUrl = currentUser?.role === 'admin'
        ? `${API_URL}/analytics/admin/hotel-info`
        : `${API_URL}/analytics/partner/hotel/info/${currentUser?._id}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error("Error response:", response);
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result);
      setCount(result.data);
    } catch (error) {
      console.error("Error fetching vendor analytics:", error);
    } finally {
      setLoader(false); // Set loading indicator to false regardless of success or failure
    }
  };


  useEffect(() => {
    getVendorAnalytics()
  }, [currentUser])

  return (
    <div>
      <WaitLoader Loading={HotelsList.isLoading} />
      <Bread icon={'ic:twotone-hotel'} tabName={'Manage Hotel'} />
      {isKyc?.isVerified === 'approved' || currentUser?.role === 'admin' ? (
        <Grid Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Total Hotels" total={count?.totalHotels === 0 ? '0' : count?.totalHotels} color="info" icon={'bi:building'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Active Hotels" total={count?.totalActiveHotel === 0 ? '0' : count?.totalActiveHotel} color="success" icon={'bi:circle-fill'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="In-Active Hotels" total={count?.totalInactiveHotel === 0 ? '0' : count?.totalInactiveHotel} color="error" icon={'bi:circle-fill'} />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <VendorHotel allHotels={HotelsList?.data} />
          </Grid>
        </Grid>
      ) : (
        <Grid Grid container spacing={1}>
          <Grid xs={12} item textAlign={'center'}>
            <Typography variant="h5">First Complete you profile using below button</Typography>
            <Button onClick={() => navigate('/dashboard/profile')} variant="contained" color="error">
              Verify
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default HotelList;
