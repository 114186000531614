import instance from '../../../utils/_utils';
import * as constant from '../../Constants/BookingConstants/BookingConstants';

export const GetBookingActionByHotelId = (hotelid) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.HOTEL_BOOKINGBYID_API_LOADING });
            const response = await instance.get(`/hotel/get/hotel-bookings/${hotelid}`);
            if (response.status === 200) {
                dispatch({ type: constant.HOTEL_BOOKINGBYID_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.HOTEL_BOOKINGBYID_API_ERROR });
        }
    };
};

export const GetAllBookingAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.HOTEL_BOOKING_API_LOADING });
            const response = await instance.get(`/hotel/book/get/bookings-admin/getall`);
            if (response.status === 200) {
                dispatch({ type: constant.HOTEL_BOOKING_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.HOTEL_BOOKING_API_ERROR });
        }
    };
};

export const GetVendorBookingAction = (userid) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.HOTEL_VENDORBOOKING_API_LOADING });
            const response = await instance.get(`/hotel/book/get/bookings/${userid}`);
            if (response.status === 200) {
                dispatch({ type: constant.HOTEL_VENDORBOOKING_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.HOTEL_VENDORBOOKING_API_ERROR });
        }
    };
};

export const GetVendorBookingStatsAction = (vendorid) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.HOTEL_VENDORBOOKING_STATS_API_LOADING });
            const response = await instance.get(`/analytics/partner/booking-info/${vendorid}`);
            if (response.status === 200) {
                dispatch({ type: constant.HOTEL_VENDORBOOKING_STATS_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.HOTEL_VENDORBOOKING_STATS_API_ERROR });
        }
    };
};

export const GetAdminBookingStatsAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.HOTEL_ADMINBOOKING_STATS_API_LOADING });
            const response = await instance.get(`/analytics/admin/booking-info`);
            if (response.status === 200) {
                dispatch({ type: constant.HOTEL_ADMINBOOKING_STATS_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.HOTEL_ADMINBOOKING_STATS_API_ERROR });
        }
    };
};