export const GETALL_VENDOR_API_LOADING = 'GETALL_VENDOR_API_LOADING';
export const GETALL_VENDOR_API_SUCCESS = 'GETALL_VENDOR_API_SUCCESS';
export const GETALL_VENDOR_API_ERROR = 'GETALL_VENDOR_API_ERROR';

// Delete the vendor
export const DELETE_VENDOR_API_LOADING = 'DELETE_VENDOR_API_LOADING';
export const DELETE_VENDOR_API_SUCCESS = 'DELETE_VENDOR_API_SUCCESS';
export const DELETE_VENDOR_API_ERROR = 'DELETE_VENDOR_API_ERROR';

// Get SIngle Vendor
export const GET_SINGLE_VENDOR_API_LOADING = 'GET_SINGLE_VENDOR_API_LOADING';
export const GET_SINGLE_VENDOR_API_ERROR = 'GET_SINGLE_VENDOR_API_ERROR';
export const GET_SINGLE_VENDOR_API_SUCCESS = 'GET_SINGLE_VENDOR_API_SUCCESS';

// Get facilities and amenities
export const GET_FACILITY_AND_AMENITIES_API_LOADING = 'GET_FACILITY_AND_AMENITIES_API_LOADING';
export const GET_FACILITY_AND_AMENITIES_API_ERROR = 'GET_FACILITY_AND_AMENITIES_API_ERROR';
export const GET_FACILITY_AND_AMENITIES_API_SUCCESS = 'GET_FACILITY_AND_AMENITIES_SUCCESS';
