import { ScaleLoader } from 'react-spinners';

const WaitLoader = ({ Loading }) => {
  return (
    <ScaleLoader
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '9999',
        transform: 'translate(-50% ,-50%)',
      }}
      color="#ff0000"
      size={80}
      loading={Loading}
    />
  );
};

export default WaitLoader;
