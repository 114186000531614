export const FAQ_CREATE_API_LOADING = 'FAQ_CREATE_API_LOADING';
export const FAQ_CREATE_API_SUCCESS = 'FAQ_CREATE_API_SUCCESS';
export const FAQ_CREATE_API_ERROR = 'FAQ_CREATE_API_ERROR';

export const FAQ_GET_API_LOADING = 'FAQ_GET_API_LOADING';
export const FAQ_GET_API_SUCCESS = 'FAQ_GET_API_SUCCESS';
export const FAQ_GET_API_ERROR = 'FAQ_GET_API_ERROR';

export const FAQ_BYFIELD_API_LOADING = 'FAQ_BYFIELD_API_LOADING';
export const FAQ_BYFIELD_API_SUCCESS = 'FAQ_BYFIELD_API_SUCCESS';
export const FAQ_BYFIELD_API_ERROR = 'FAQ_BYFIELD_API_ERROR';

export const FAQ_UPDATE_API_LOADING = 'FAQ_UPDATE_API_LOADING';
export const FAQ_UPDATE_API_SUCCESS = 'FAQ_UPDATE_API_SUCCESS';
export const FAQ_UPDATE_API_ERROR = 'FAQ_UPDATE_API_ERROR';
