import axios from 'axios';
import Swal from 'sweetalert2';
import React, { useContext, useState } from 'react';
import { API_URL } from '../config';

// Replace with your API URL

const UtilsContext = React.createContext();

const UtilsProvider = ({ children }) => {
    const [EmailResponse, setEmailResponse] = useState(null)
    // send Email
    const sendEmailVerify = async (formData) => {
        const queryparmas = new URLSearchParams(formData).toString()
        try {
            const response = await axios.get(`${API_URL}/verify/email?${queryparmas}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                Swal.fire({
                    title: 'Verification Code Sent',
                    icon: 'success',
                    text: 'We have sent a verification code to your email. Please enter the verification code.',
                });
                setEmailResponse(response.data)
            } else {
                Swal.fire({
                    title: 'Verification Error',
                    icon: 'error',
                    text: 'Verification code not sent. Please try again.',
                });
            }
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <UtilsContext.Provider value={{ EmailResponse, setEmailResponse, sendEmailVerify }}>
            {children}
        </UtilsContext.Provider>
    );
};

const useUtilsContext = () => {
    return useContext(UtilsContext);
};

export { useUtilsContext, UtilsProvider };
