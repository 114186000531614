import axios from "axios";
import * as constant from "../../Constants/AuthConstants/VendorVerificationConstant";
import { API_URL } from "../../../config";
import instance from "../../../utils/_utils";

export const GetVendorDetailsSubmit = (formdata, otpid, email, otp, cid) => {
    return async (dispatch) => {
        dispatch({ type: constant.VENDOR_VERIFY_API_LOADING })
        const urlQuery = new URLSearchParams({ otpid, email, otp, cid }).toString()
        const BodyData = {
            ...formdata,
            email,
            vendorId: cid
        }
        const VerificationQuery = new URLSearchParams({ otpid, otp }).toString()
        try {
            // verify otp and send req 
            const otpVerified = await instance.get(`/verify/verify-otp?${VerificationQuery}`)
            if (otpVerified) {
                const response = await instance.post(`/kyc/request`, BodyData)
                if (response.status === 200) {
                    dispatch({ type: constant.VENDOR_VERIFY_API_SUCCESS, payload: response.data })
                }
            }
        } catch (error) {
            dispatch({ type: constant.VENDOR_VERIFY_API_ERROR })
        }
    }
}