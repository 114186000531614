import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Button, Card, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Bread from '../Bread/Bread';
import RoomOpertions from './RoomOpertions';
import RoomList from './RoomList';
import { UseStateManager } from '../../Context/StateManageContext';
import { GetUserHotelData } from '../../Store/Actions/HotelActions/HotelCurdAction';
import WaitLoader from '../../utils/WaitLoader';

const Room = () => {
  const { currentUser, isKyc } = UseStateManager();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [manageRoom, setmanageRoom] = useState(null);
  const handelsBack = () => { setmanageRoom(null) }
  const hotellist = useSelector((state) => state.GetAllHotelReducer);

  const hotelData = hotellist.data?.find((x) => manageRoom === x._id);

  const [filteredhotel, setHotelFiltered] = useState([]);

  const [loader, setLoader] = useState(false);

  const handelRoomManagement = (id) => {
    setmanageRoom(id);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(GetUserHotelData(currentUser?._id, currentUser?.role)).then(() => {
      setLoader(false);
    });
  }, [currentUser]);

  const HandleFilterData = (newValue) => {
    if (!newValue) {
      const hotelNames = hotellist.data.map((item) => item.hotelName);
      setHotelFiltered([...hotelNames]);
    } else {
      setHotelFiltered([...filteredhotel.filter((x) => x === newValue)]);
    }
  };

  useEffect(() => {
    const hotelNames = hotellist.data.map((item) => item.hotelName);
    setHotelFiltered([...hotelNames]);
  }, [hotellist.data]);

  return (
    <div>
      <WaitLoader Loading={loader} />

      {isKyc?.isVerified === 'approved' ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Bread tabName={'Room Management'} />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={hotellist?.data?.map((item, index) => item.hotelName)}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                HandleFilterData(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Search Hotel" />}
            />
          </div>
          {manageRoom !== null ? (
            <RoomOpertions handelsBack={handelsBack} handelRoomManagement={handelRoomManagement} hotelData={hotelData} manageRoom={manageRoom} />
          ) : (
            <RoomList handelRoomManagement={handelRoomManagement} hotellist={hotellist} filteredhotel={filteredhotel} />
          )}
        </>
      ) : (
        <Grid container spacing={1}>
          <Grid xs={12} item textAlign={'center'}>
            <Typography variant="h5">First Complete you profile using below button</Typography>
            <Button onClick={() => navigate('/dashboard/profile')} variant="contained" color="error">
              Verify
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Room;
