
import * as constant from "../../Constants/RoomManagementConstant/RoomManagementConstant"




const initialValues = {
    isLoading: false,
    isError: false,
    data: []
}


const GetAddTheRoomReducer = (state = initialValues, action) => {
    switch (action.type) {
        case constant.ADD_NEW_ROOM_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.ADD_NEW_ROOM_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        case constant.ADD_NEW_ROOM_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return {
                ...state
            };
    }
}



const GetUpdateRoomData = (state = initialValues, action) => {
    switch (action.type) {
        case constant.UPDATE_ROOM_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.UPDATE_ROOM_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        case constant.UPDATE_ROOM_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return {
                ...state
            };
    }
}



export { GetAddTheRoomReducer, GetUpdateRoomData }