import axios from 'axios'
import instance from '../../../utils/_utils'
import * as constant from '../../Constants/adminConstants/managecustomer_constant'




export const GetAllCustomer = () => {
    return async (dispatch) => {
        dispatch({ type: constant.GET_ALL_CUSTOMER_LOADING })
        try {
            const response = await instance.get(`/admin/allcustomer`)
            dispatch({ type: constant.GET_ALL_CUSTOMER_SUCCESS, payload: response.data.data })
        } catch (error) {
            dispatch({ type: constant.GET_ALL_CUSTOMER_ERROR })
        }
    }
}




export const DeleteCustomerById = async (id) => {
    try {
        const response = await instance.get(`/admin/customer/delete/${id}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error(error);
    }
    return false;
};