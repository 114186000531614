import * as constant from '../Constants/locationConstant';

const initialValues = {
  isLoading: false,
  isError: false,
  message: '',
  isSuccess: false,
  data: [],
};

const CreateLocationReducer = (state = initialValues, action) => {
  switch (action.type) {
    case constant.CREATE_LOCATION_API_LOADING:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    case constant.CREATE_LOCATION_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload,
      };
    case constant.CREATE_LOCATION_API_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action?.message,
        isError: true,
      };

    default:
      return state;
  }
};

const UpdateLoationReducers = (state = initialValues, action) => {
  switch (action.type) {
    case constant.UPDATE_LOCATION_API_LOADING:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    case constant.UPDATE_LOCATION_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload,
      };
    case constant.UPDATE_LOCATION_API_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action?.message,
        isError: true,
      };

    default:
      return state;
  }
};

const GetLocationsReducers = (state = initialValues, action) => {
  switch (action.type) {
    case constant.GET_LOCATION_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.GET_LOCATION_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload,
      };
    case constant.GET_LOCATION_API_ERROR:
      return {
        ...state,
        isLoading: false,
        message: action?.message,
        isError: true,
      };

    default:
      return state;
  }
};

export { CreateLocationReducer, UpdateLoationReducers, GetLocationsReducers };
