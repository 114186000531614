import { Link, Navigate, useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import { RingLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import GetVendorLoginReducer from '../Store/Reducers/AuthReducers/VendorLoginReducer';
import NewLogin from './NewLogin';
import { UseStateManager } from '../Context/StateManageContext';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  // maxWidth: 480,
  // display: 'flex',
  // flexDirection: 'column',
  // justifyContent: 'center',
  display: 'grid',
  placeItems: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
}));

const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  // display: 'flex',
  // justifyContent: 'center',
  // flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();

  // api login data
  const loginData = useSelector((pre) => pre.GetVendorLoginReducer);

  const mdUp = useResponsive('up', 'md');

  // active not active vendor or admin
  const [isRole, setIsRole] = useState('vendor');

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 20 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ position: 'absolute', top: '15%' }}>
              Welcome To Hotelio, Your Travel Partner
            </Typography>
            <img src="/assets/illustrations/illustration_login.svg" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <RingLoader
            color="#ff543b"
            loading={loginData.isLoading}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50% , -50%)',
            }}
          />
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to Hotelio 🏨
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={() => setIsRole('vendor')}
                sx={{
                  backgroundColor: isRole === 'vendor' ? '#ff5319' : 'transparent',
                  color: isRole === 'vendor' ? '#ffffff' : '#ff5319',
                }}
              >
                I am Vendor
              </Button>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={() => setIsRole('admin')}
                sx={{
                  backgroundColor: isRole === 'admin' ? '#ff5319' : 'transparent',
                  color: isRole === 'admin' ? '#ffffff' : '#ff5319',
                }}
              >
                I am Admin
              </Button>
            </div>

            {/* <LoginForm isRole={isRole} /> */}

            <NewLogin isRole={isRole} />

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider>
            <Typography variant="p" sx={{ mt: 5, mr: 1 }}>
              New on our platform?
            </Typography>
            <Link to="/register">Create an account</Link>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
