import instance from '../../../utils/_utils';
import * as constant from '../../Constants/DashStaticsConstants/DashStaticsConstants';

export const GetDashStatsAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_STATS_API_LOADING });
            const response = await instance.get(`/analytics/admin/dashboard/info/users`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_STATS_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_STATS_API_ERROR });
        }
    };
};

export const GetDashStatsHAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_STATS_H_API_LOADING });
            const response = await instance.get(`/analytics/admin/dashboard/info/booking`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_STATS_H_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_STATS_H_API_ERROR });
        }
    };
};