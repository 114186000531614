import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CustomerTable from '../components/Tables/CustomerTable'
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Bread from '../components/Bread/Bread';
import Header from '../layouts/dashboard/header';
import Nav from '../layouts/dashboard/nav';
import { GetAllCustomer } from '../Store/Actions/AdminActions/allcustomers';
import GetAllCustomerReducers from '../Store/Reducers/adminReducer/customerReducers';


const Customer = () => {

  const userAnalytics = (data) => {
    const currentDate = new Date();
    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(currentDate.getDate() - 7);

    const beforeWeek = data.filter(item => new Date(item.createdAt) < oneWeekAgo);
    const inWeek = data.filter(item => new Date(item.createdAt) >= oneWeekAgo);

    return {
      beforeWeek,
      inWeek,
    };
  };







  const data = useSelector(state => state.GetAllCustomerReducer)
  const dispatch = useDispatch()

  const APP_BAR_MOBILE = 64;
  const APP_BAR_DESKTOP = 92;

  const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
  });
  const [open, setOpen] = useState(false);

  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  const { beforeWeek, inWeek } = userAnalytics(data.data)





  useEffect(() => {
    dispatch(GetAllCustomer())
  }, [dispatch])

  return (
    <StyledRoot>
      {console.log(data)}
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
        <Bread icon={'solar:user-bold-duotone'} tabName={'Manage Customer'} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Users"
              total={data.data?.length}
              color="info"
              icon={'solar:user-bold-duotone'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Users ( This Week ) "
              total={inWeek.length > 0 ? inWeek.length : 0}
              color="success"
              icon={'icon-park-twotone:up-two'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Non-Active Users"
              total={11}
              color="error"
              icon={'icon-park-twotone:down-two'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <CustomerTable data={data.data} />
          </Grid>
        </Grid>

      </Main>

    </StyledRoot>
  )
}

export default Customer