import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DeleteCoupon, GetAllCouponAction } from '../../Store/Actions/HotelioCouponActions/HotelioCouponAction';

export default function CouponsTable({ Data }) {

    const dispatch = useDispatch();

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    };

    const handleDelete = (couponId) => {

        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // User confirmed, dispatch the delete action
                dispatch(DeleteCoupon(couponId));

                // Show a success Swal notification
                Swal.fire(
                    'Deleted!',
                    'Your offer has been deleted.',
                    'success'
                );
                // Optionally, re-fetch the data after deletion
                dispatch(GetAllCouponAction());
            }
        });
    };

    const DataWithID = (data) => {
        const newData = [];
        if (data !== undefined) {
            data?.forEach((item, index) => {
                newData.push({ ...item, id: index + 1 });
            });
        } else {
            newData.push({ id: 0 });
        }
        return newData;
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },

        {
            field: 'couponCode',
            headerName: 'Code',
            width: 200,
            renderCell: (params) => (
                <div>
                    <b>{params.row.couponCode}</b>
                </div>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Start Date',
            width: 200,
            renderCell: (params) => moment(params.row?.session?.from).format('MM DD YYYY')
        },
        {
            field: 'expiry',
            headerName: 'Expiry',
            width: 200,
            renderCell: (params) => moment(params.row?.session?.to).format('MM DD YYYY')
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => (
                <div style={{ display: 'flex' }}>
                    <DeleteForeverIcon onClick={() => handleDelete(params.row?._id)} color='error' />
                </div>
            )
        }

    ];


    return (
        <div style={{ minheight: '100vh', width: '100%', marginTop: '8px' }}>
            <DataGrid
                rows={DataWithID(Data)}
                columns={columns}
                density="comfortable"
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{ toolbar: CustomToolbar }}
            />
        </div>
    );
}