import React from 'react'
import ManageRoomAmmenities from './ManageRoomAmmenities'

const RoomAndPrice = () => {
    return (
        <div>
            <ManageRoomAmmenities />
        </div>
    )
}

export default RoomAndPrice