import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import DOMPurify from 'dompurify';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { CreateFaqAction, GetFaqByUser, UpdateFaqAction } from '../../Store/Actions/faqActions';
import 'react-quill/dist/quill.snow.css';
import { UseStateManager } from '../../Context/StateManageContext';
import instance from '../../utils/_utils';

const AddFaqFunction = () => {
  const dispatch = useDispatch();
  const allFaqList = useSelector((state) => state.GetFaqByUserReducer);
  const addedFaq = useSelector((state) => state.CreateTheFaqReducer);
  const updateFaq = useSelector((state) => state.UpdateFaqReducer);
  const { currentUser } = UseStateManager();

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'], // Include the 'link' option for adding links
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'background',
    'code-block',
    'script',
  ];

  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const handleDeleteModal = () => {
    setIsDeleteModal(false);
  };

  const [IsEditFaq, setIsEditFaq] = useState(false);

  const [faqFromData, setFaqFormData] = useState({ user: '', question: '', answer: '' });
  const handleChangeFaqData = (name, value) => {
    setFaqFormData({ ...faqFromData, [name]: value });
  };

  const [editData, setEditData] = useState(null);
  const HandleQuestionEdit = (e, faq) => {
    e.stopPropagation();
    setEditData(faq);
    setIsEditFaq(true);
    setFaqFormData({ user: faq?.user, question: faq?.question, answer: faq?.answer });
  };

  const HandleFaqCreate = async (faqFromData, currentUser) => {
    const data = faqFromData;
    data.answer = DOMPurify.sanitize(data.answer);
    data.user = currentUser?._id;
    await dispatch(CreateFaqAction(data)).then(() => {
      if (!addedFaq.isLoding && !addedFaq.isError) {
        Swal.fire({ icon: 'success', text: 'successfully added ' });
        setFaqFormData({});
        dispatch(GetFaqByUser({ user: currentUser?._id }));
      }
    });
  };

  const DeleteFaq = async (id, currentUser) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await instance.delete(`/faq/delete/${id}`);
        if (response.status === 200) {
          dispatch(GetFaqByUser({ user: currentUser?._id }));
          Swal.fire({
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            icon: 'success',
          });
        }
      }
    });
  };

  const HandleUpdateFaq = async (id, formData) => {
    try {
      await dispatch(UpdateFaqAction(id, formData));
      if (!updateFaq.isLoding && !updateFaq.isError) {
        Swal.fire({ icon: 'success', text: 'Updated Data' });
        // setIsEditFaq(false);
        setFaqFormData({});
      }
    } catch (error) {
      console.error('Error updating FAQ:', error);
    }
  };

  useEffect(() => {
    dispatch(GetFaqByUser({ user: currentUser?._id }));
  }, [currentUser]);

  useEffect(() => {
    if (IsEditFaq) {
      setFaqFormData({ user: editData?.user, question: editData?.question, answer: editData?.answer });
    } else {
      setFaqFormData({});
    }
  }, [IsEditFaq]);

  return (
    <>
      {/* <Bread icon={'wpf:faq'} tabName={'Add FAQ'} /> */}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography py={2} variant="h6">
            Question Answer Preview
          </Typography>
          {allFaqList?.data?.data ? (
            <Box sx={{ overflowY: 'scroll', maxHeight: '400px' }}>
              {allFaqList?.data?.data?.map((faq, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography fontWeight={800}>{`Q${index + 1}. ${faq.question}`}</Typography>
                    {IsEditFaq ? (
                      ''
                    ) : (
                      <IconButton sx={{ ml: 'auto' }} color="primary" onClick={(e) => HandleQuestionEdit(e, faq)}>
                        <EditIcon color="error" />
                      </IconButton>
                    )}
                    <IconButton
                      sx={IsEditFaq && { ml: 'auto' }}
                      onClick={() => DeleteFaq(faq?._id, currentUser)}
                      color="secondary"
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography dangerouslySetInnerHTML={{ __html: faq.answer }} color={'grey'} variant="subtitle2" />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ) : (
            '...loading'
          )}
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography py={2} variant="h6">
              {IsEditFaq ? 'Edit' : 'Add'} Question Answer
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="question"
                  value={faqFromData.question}
                  label="Add Question"
                  fullWidth
                  onChange={(e) => handleChangeFaqData(e.target.name, e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <ReactQuill
                  theme="snow"
                  value={faqFromData.answer}
                  onChange={(value) => handleChangeFaqData('answer', value)}
                  modules={modules}
                  formats={formats}
                  style={{ borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs={12}>
                {IsEditFaq ? (
                  <Button variant="contained" type="button" onClick={() => HandleUpdateFaq(editData?._id, faqFromData)}>
                    Save
                  </Button>
                ) : (
                  <Button variant="contained" onClick={() => HandleFaqCreate(faqFromData, currentUser)}>
                    Add
                  </Button>
                )}
                {IsEditFaq && (
                  <Button onClick={() => setIsEditFaq(false)} variant="outlined" sx={{ ml: 1 }}>
                    Cancel
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddFaqFunction;
