import instance from '../../utils/_utils';
import * as constant from '../Constants/locationConstant';

export const CreateLocationAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: constant.CREATE_LOCATION_API_LOADING });
    try {
      const response = await instance.post('/popular-location/create', formData);
      if (response.status === 200) {
        dispatch({ type: constant.CREATE_LOCATION_API_SUCCESS, payload: response.data });
      } else if (response.status === 409) {
        dispatch({ type: constant.CREATE_LOCATION_API_ERROR, message: 'This Location URL is already registered' });
      }
    } catch (error) {
      dispatch({ type: constant.CREATE_LOCATION_API_ERROR, message: error.message });
    }
  };
};

export const UpdateLoationAction = (id, formData) => {
  return async (dispatch) => {
    dispatch({ type: constant.UPDATE_LOCATION_API_LOADING });
    try {
      const response = await instance.patch(`/popular-location/update/${id}`, formData);
      if (response.status === 200) {
        dispatch({ type: constant.UPDATE_LOCATION_API_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: constant.UPDATE_LOCATION_API_ERROR });
    }
  };
};

export const GetLocationsAction = () => {
  return async (dispatch) => {
    dispatch({ type: constant.GET_LOCATION_API_LOADING });
    try {
      const response = await instance.get('/popular-location/getall');
      if (response.status === 200) {
        dispatch({ type: constant.GET_LOCATION_API_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: constant.GET_LOCATION_API_ERROR });
    }
  };
};
