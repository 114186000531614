// VendorProfile.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Button, Fade, Box, CardActions, CardContent, Grid, Card, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import HotelIcon from '@mui/icons-material/Hotel';
import VerifiedIcon from '@mui/icons-material/Verified';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UseStateManager } from '../Context/StateManageContext';
import VendorVerificationForm from '../components/Forms/VendorVerificationForm';
import GlobalModel from '../components/GlobalModal/GlobalModel';
import AdminProfile from './AdminProfile';
import BankAccountForm from '../components/Forms/BankAccountForm';
import UpdateBankAccountForm from '../components/Forms/UpdateBankAccountForm';
import { GetBankDetailsAction } from '../Store/Actions/BankDetailActions';
import { GetFileUploaded } from '../Store/Actions/ImageUploadActions/UploadActions';


const CompletedProfile = () => {
    const { currentUser, isKyc, DeleteBankAccount, setLoader, UploadImageUrl } = UseStateManager();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openBank, setOpenBank] = useState(false);
    const handleOpenBank = () => setOpenBank(true);
    const handleCloseBank = () => setOpenBank(false);
    const [openUpdateBank, setOpenUpdateBank] = useState(false);
    const handleOpenUpdateBank = () => setOpenUpdateBank(true);
    const handleCloseUpdateBank = () => setOpenUpdateBank(false);
    const BankData = useSelector(state => state.BankDetailReducers);
    const UploadedImage = useSelector((state) => state.UploadReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const fetchData = async (currentUser) => {
        try {
            await dispatch(GetBankDetailsAction(currentUser?._id));
        } catch (error) {
            console.error('Error fetching bank details:', error);
        }
    };

    useEffect(() => {
        const fetchDataIfVendor = async () => {
            try {
                if (currentUser.role === 'vendor') {
                    await fetchData(currentUser);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDataIfVendor();
    }, [currentUser]);


    console.log(currentUser)

    useEffect(() => {
        if (UploadedImage.isSuccess === true) {
            console.log('Avatar filename from useEffect:', UploadedImage.fileName.avatar);
        }
    }, [UploadedImage]);

    // Get File Upload  
    const handleGetFileUploaded = async (e, id) => {
        try {
            setLoader(true);

            await dispatch(GetFileUploaded(e, id));

            console.log('Avatar filename from handleGetFileUploaded:', UploadedImage?.fileName.avatar);

            const avatarFileName = UploadedImage?.fileName?.avatar;

            const userId = currentUser?._id;

            await UploadImageUrl(userId, avatarFileName);
        } catch (error) {
            console.error('Error during file upload:', error);
        } finally {
            setLoader(false);
        }
    };



    if (!currentUser) {
        return (
            <Container>
                <Typography variant="h4">Vendor Profile</Typography>
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="body1">User not found.</Typography>
                </Paper>
            </Container>
        );
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
    };


    return (
        <Container>
            {
                currentUser?.role === 'admin' ? <AdminProfile onChange={(e) => handleGetFileUploaded(e, currentUser._id)} /> : <>
                    <GlobalModel open={open} handleClose={handleClose} content={
                        <Fade in={open}>
                            <Box sx={style}>
                                <VendorVerificationForm />
                            </Box>
                        </Fade>
                    } />

                    <Typography py={2} variant="h4">Vendor Profile</Typography>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: '100%', p: 1, background: 'linear-gradient(90deg, rgba(243,200,198,1) 35%, rgba(255,255,255,1) 100%)' }}>
                                <Box >
                                    <Typography p={2} variant="h6">Personal Information</Typography>
                                    <Box sx={{ display: 'grid', placeItems: 'center' }}>
                                        <img style={{ height: '400px' }} src={currentUser?.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='...' />
                                    </Box>
                                    <Box textAlign={'center'} mt={1}>
                                        <Button onClick={() => document.getElementById('clickMe').click()} variant='contained'>Update Profile Image</Button>
                                        <input id='clickMe' style={{ display: 'none' }} type="file" name='avatar' accept="image/*" onChange={(e) => handleGetFileUploaded(e, currentUser._id)} />
                                    </Box>
                                    <CardContent >

                                        <TableContainer component={Paper} sx={{ bgcolor: 'transparent' }}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography fontWeight={700} variant="body1">Name:</Typography>
                                                        </TableCell>
                                                        <TableCell>{currentUser.name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography fontWeight={700} variant="body1">Email:</Typography>
                                                        </TableCell>
                                                        <TableCell>{currentUser.email}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography fontWeight={700} variant="body1">Mobile Number:</Typography>
                                                        </TableCell>
                                                        <TableCell>{currentUser.mobileNo}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </Box>
                            </Card>
                            <Card sx={{ maxWidth: '100%', p: 1, mt: 1 }}>
                                <Typography p={2} variant="h6" > Bank Account Details </Typography>
                                {currentUser?.bankDetails && <CardContent >
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <Typography fontWeight={700}>Bank Name:</Typography>
                                                    </TableCell>
                                                    <TableCell>{BankData?.data?.[0]?.bankName}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <Typography fontWeight={700}>Name:</Typography>
                                                    </TableCell>
                                                    <TableCell>{BankData?.data?.[0]?.accountHolderName}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <Typography fontWeight={700}>A/C no.:</Typography>
                                                    </TableCell>
                                                    <TableCell>{BankData?.data?.[0]?.accountNo}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <Typography fontWeight={700}>IFSC:</Typography>
                                                    </TableCell>
                                                    <TableCell>{BankData?.data?.[0]?.ifsc}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <Typography fontWeight={700}>Linked No.:</Typography>
                                                    </TableCell>
                                                    <TableCell>{BankData?.data?.[0]?.linkedMobileNo}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>}
                                <CardActions>
                                    {isKyc?.isVerified === 'approved' && <Button size='small' onClick={!currentUser?.bankDetails ? handleOpenBank : handleOpenUpdateBank} variant='contained'><AccountBalanceIcon /> {!currentUser?.bankDetails ? 'Add Bank Detail' : 'Update Bank Detail'} </Button>}
                                    {currentUser?.bankDetails && <Button onClick={() => DeleteBankAccount(currentUser?._id, BankData?.data[0]?._id)} size="small">Delete</Button>}
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: '100%', p: 1, mb: 1 }}>
                                <Typography p={2} variant="h6" >KYC Documents</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="h6" >Verification Status</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: '10px', }} variant="body1">
                                                        KYC Verified: {currentUser?.kycVerified && isKyc?.isVerified === "approved" ? (
                                                            <VerifiedIcon className='animated-scale' fontSize='large' color="success" />
                                                        ) : (
                                                            isKyc ? `${isKyc?.isVerified}` : "Apply For KYC"
                                                        )}
                                                        <div style={{ position: 'relative' }}>
                                                            <div style={{ position: 'absolute', left: '-95%', top: '70%' }}>
                                                                {currentUser?.kycVerified && isKyc?.isVerified === "approved" ? null : <img className='animated-image' style={{ width: '50px' }} src="/assets/pointer.png" alt="pointer" />}
                                                            </div>
                                                            {isKyc?.isVerified === 'approved' || isKyc?.isVerified === undefined ? <Button variant='contained' onClick={handleOpen} color='primary'>{currentUser?.kycVerified && isKyc?.isVerified === "approved" ? 'Update' : 'Verify'}</Button> : null}
                                                        </div>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {currentUser?.kyc && <CardContent>
                                    <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                                        <img style={{ height: '250px' }} src={currentUser?.kyc?.[0]?.panImg} alt='...' />
                                        {
                                            currentUser?.kyc?.[0]?.aadharImg.map((item, index) => (
                                                <img style={{ height: '250px' }} key={index} src={item} alt='...' />
                                            ))
                                        }
                                    </Box>
                                </CardContent>}
                            </Card>
                            <Card sx={{ maxWidth: '100%', p: 1, mt: 1, background: 'linear-gradient(338deg, rgba(243,200,198,1) 35%, rgba(255,255,255,1) 100%)' }}>
                                <Typography p={2} variant="h6">Our Hotels</Typography>
                                <CardContent>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <HotelIcon color='error' />
                                        <Typography sx={{ pl: 1, cursor: 'pointer' }} onClick={() => navigate('/dashboard/list')} variant="body1">Hotel Counts: <b style={{ color: '#ee2e24' }} >{currentUser.hotels && currentUser.hotels.length}</b></Typography>
                                        {isKyc?.isVerified === 'approved' && <Button sx={{ ml: 3 }} onClick={() => navigate('/dashboard/add')} variant='contained'> Add hotel</Button>}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </>
            }
            <GlobalModel open={openBank} handleClose={handleCloseBank} content={
                <Fade in={openBank}>
                    <Box sx={style}>
                        <BankAccountForm handleCloseBank={handleCloseBank} />
                    </Box>
                </Fade>
            } />
            <GlobalModel open={openUpdateBank} handleClose={handleCloseUpdateBank} content={
                <Fade in={openUpdateBank}>
                    <Box sx={style}>
                        <UpdateBankAccountForm handleUpdateBankForm={handleCloseUpdateBank} />
                    </Box>
                </Fade>
            } />

        </Container >
    );
};

export default CompletedProfile;
