import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { isMobile, isMobileOnly } from 'react-device-detect';
import 'react-toastify/dist/ReactToastify.css';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import SimpleLayout from './layouts/simple/SimpleLayout';
import RegisterLayout from './layouts/register/RegisterLayout';
import VendorProfileLayout from './layouts/VendorProfileLayout/VendorProfileLayout';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import VendorVerification from './pages/VendorVerification';
import Customer from './pages/Customer';
import Booking from './pages/Booking';
import NotificationMgmt from './pages/NotificationMgmt';
import Page404 from './pages/Page404';
import ManageOffer from './pages/ManageOffer';
import AddHotels from './pages/AddHotels';
import HotelsList from './pages/HotelsList';
import Roommanagement from './pages/Roommanagement';
import Instruction from './pages/Instruction';
import PromotionalActivity from './pages/PromotionalActivity';
import ManageAddPartner from './pages/ManageAddPartner';
import ManageListPartner from './pages/ManageListPartner';
import ManageKycVerification from './pages/ManageKycVerification';
import ManageCoupons from './pages/ManageCoupons';

import RegulatePayment from './pages/RegulatePayment';
import VendorNotificationPage from './pages/VendorNotificationPage';
import './App.css';
import ManageReviewComment from './pages/ManageReviewComment';
import ManageTravelAgents from './pages/ManageTravelAgents';
import Roomavailability from './pages/RoomAvailability';
import RoomAndPriceManagement from './pages/RoomAndPriceManagement';
import ManageWallet from './pages/ManageWallet';
import FaqManagement from './pages/FaqManagement';
import AddFaq from './pages/AddFaq';
import HotelWiseFaq from './pages/HotelWiseFaq';
import HomeSliderLayout from './pages/HomeSliderLayout';
import HotelBookings from './pages/HotelBookings';
import ReviewRating from './pages/ReviewRating';
import NotificationConfig from './pages/NotificationConfig';
import NotificationMgmtById from './pages/NotificationMgmtById';
import HotelVerfication from './pages/HotelVerfication';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      {isMobileOnly || isMobile ? (
        <Instruction />
      ) : (
        <BrowserRouter>
          <ThemeProvider>
            <ToastContainer stacked />
            <ScrollToTop />
            <StyledChart />
            <div>
              {/* <MainNav /> */}
              <div>
                <Routes>
                  <Route exact path="/" element={<SimpleLayout />} />
                  <Route exact path="/register" element={<RegisterLayout />} />
                  <Route exact path="/vendorName/:id" element={<VendorProfileLayout />} />
                  <Route exact path="/dashboard">
                    <Route path="app" element={<DashboardLayout />} />
                    <Route path="profile" element={<VendorVerification />} />
                    <Route path="addPartner" element={<ManageAddPartner />} />
                    <Route path="listPartner" element={<ManageListPartner />} />
                    <Route path="kycVerification" element={<ManageKycVerification />} />
                    <Route path="add" element={<AddHotels />} />
                    <Route path="list" element={<HotelsList />} />
                    <Route path="hotel-verfication" element={<HotelVerfication />} />
                    <Route path="roomManagment" element={<Roommanagement />} />
                    <Route path="roomAvailablity" element={<Roomavailability />} />
                    <Route path="manageCustomer" element={<Customer />} />
                    <Route path="manage-Travel-Agent" element={<ManageTravelAgents />} />
                    <Route path="booking" element={<Booking />} />
                    <Route path="hotel-bookings" element={<HotelBookings />} />
                    <Route path="manageOffer" element={<ManageOffer />} />
                    <Route path="manageCoupons" element={<ManageCoupons />} />
                    <Route path="regulatePayment" element={<RegulatePayment />} />
                    <Route path="notification" element={<NotificationConfig />} />
                    <Route path="Notification-Configuration" element={<NotificationMgmt />} />
                    <Route path="Notification-Configuration/:id" element={<NotificationMgmtById />} />
                    <Route path="promotionalActivity" element={<PromotionalActivity />} />
                    <Route path="vendorNotification" element={<VendorNotificationPage />} />
                    <Route path="manageReviewComment" element={<ManageReviewComment />} />
                    <Route path="wallet-Management" element={<ManageWallet />} />
                    <Route path="room-Price-Management" element={<RoomAndPriceManagement />} />
                    <Route path="add-faq" element={<AddFaq />} />
                    <Route path="location-wise-faq" element={<FaqManagement />} />
                    <Route path="hotel-wise-faq" element={<HotelWiseFaq />} />
                    <Route path="website-management" element={<HomeSliderLayout />} />
                    <Route path="review-rating" element={<ReviewRating />} />
                  </Route>
                  <Route exact path="*" element={<Page404 />} />
                </Routes>
              </div>
            </div>

            {/* <Router /> */}
          </ThemeProvider>
        </BrowserRouter>
      )}
    </HelmetProvider>
  );
}
