


export const GET_ALL_LIST_KYC_LOADING = "GET_ALL_LIST_KYC_LOADING"
export const GET_ALL_LIST_KYC_ERROR = "GET_ALL_LIST_KYC_ERROR"
export const GET_ALL_LIST_KYC_SUCCESS = "GET_ALL_LIST_KYC_SUCCESS"


// KYC DELETE API 


export const GET_ALL_DELETE_KYC_LOADING = "GET_ALL_DELETE_KYC_LOADING"
export const GET_ALL_DELETE_KYC_ERROR = "GET_ALL_DELETE_KYC_ERROR"
export const GET_ALL_DELETE_KYC_SUCCESS = "GET_ALL_DELETE_KYC_SUCCESS"



// MAKE ACTION TO KYC 
export const MAKE_ACTION_KYC_LOADING = "MAKE_ACTION_KYC_LOADING"
export const MAKE_ACTION_KYC_ERROR = "MAKE_ACTION_KYC_ERROR"
export const MAKE_ACTION_KYC_SUCCESS = "MAKE_ACTION_KYC_SUCCESS"



