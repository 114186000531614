import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Button, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-quill/dist/quill.snow.css';
import EmailActivity from '../components/PromotionalActivity/EmailActivity';
import SmsActivity from '../components/PromotionalActivity/SmsActivity';

const SmsEmailActivityPage = () => {
  const [text, setText] = useState('');
  const [promotion, setPromotion] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const handleTextChange = (value) => {
    setText(value);
  };

  const handleSelectedActivity = (activity) => {
    setSelectedActivity(activity);
    setPromotion(true);
  };

  return (
    <>
      {promotion ? null : (
        <div style={{ display: 'flex' }}>
          <SmsActivity handelselcted={() => handleSelectedActivity('Sms')} />
          <EmailActivity handelselcted={() => handleSelectedActivity('Email')} />
        </div>
      )}
      {promotion ? (
        <div>
          <div style={{ display: 'flex' }} >
            <IconButton onClick={() => setPromotion(!promotion)}><ArrowBackIcon color='error' /></IconButton>
            <Typography variant='h4'>{selectedActivity} Activity</Typography>
          </div>
          <ReactQuill
            value={text}
            onChange={handleTextChange}
            modules={SmsEmailActivityPage.modules}
            formats={SmsEmailActivityPage.formats}
            placeholder="Write something..."
          />
          <Button sx={{ m: 1 }} color='primary' variant='contained'>Send {selectedActivity}</Button>
        </div >
      ) : null}
    </>
  );
};

SmsEmailActivityPage.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    ['link'],
    ['clean'],
    ['sub', 'super'],
    ['blockquote', 'code-block'],
    [{ 'align': [] }],
    ['image', 'video'],
    ['formula'],
    [{ 'list': 'check' }],
    ['emoji'],
    ['highlight', 'background'],
  ],
};

SmsEmailActivityPage.formats = [
  'header',
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'link',
  'blockquote',
  'code-block',
  'align',
  'image',
  'video',
  'formula',
  'list',
  'emoji',
  'highlight',
];

export default SmsEmailActivityPage;
