import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Formik } from 'formik';
import axios from 'axios';
import { API_URL } from '../config';
import { GetVendorSignUp } from '../Store/Actions/AuthActions/VendorActions';
import Iconify from '../components/iconify/Iconify';



// components
// 
// ----------------------------------------------------------------------

export default function RegisterForm() {

    const SignupData = useSelector(pre => pre.GetVendorSignUpReducer)
    const [showOtpScreen, setShowOtpScreen] = useState(false)
    const [otp, setOtp] = useState("")
    const [otpLoader, setOtpLoader] = useState(false)
    const [Loader, setLoader] = useState(false)
    const [otpResp, setOptResp] = useState({})
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const [showPassword, setShowPassword] = useState(false);




    const initialValues = {
        name: "",
        email: "",
        mobileNo: "",
        otp: "",
        role: "vendor",
        status: true,
        password: ""
    }

    const handleSignupSubmit = (values, { resetForm }) => {

        const otpformd = {}
        otpformd.otp = otp
        otpformd.otpid = otpResp
        setLoader(true)
        dispatch(GetVendorSignUp(values, otpformd)).then(() => {
            if (SignupData.data) {
                setLoader(false)
                resetForm()
            }
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
    }

    const sendOtp = async (number) => {
        setOtpLoader(true)
        try {
            const response = await axios.get(`${API_URL}/verify/mobile/${number}`)
            if (response.status === 200) {
                setOptResp(response.data.data)
                setShowOtpScreen(true)
                setOtpLoader(false)
                Swal.fire({
                    icon: 'success',
                    text: `we sended a otp on ${number}`
                })
            }
        } catch (error) {
            setOtpLoader(false)
            Swal.fire({
                icon: 'error',
                text: "failed to send otp "
            })
        }

    }

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSignupSubmit}>
                {({ values, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit} >
                        {!showOtpScreen ? <>
                            <Stack spacing={2}>
                                <TextField type='text' name="name" onChange={handleChange} value={values.name} label="Full Name" />

                                <TextField type='email' name="email" onChange={handleChange} value={values.email} label="Email address" />


                                <TextField type='number' onChange={handleChange} value={values.mobileNo} name="mobileNo" label="Mobile No." />

                                <TextField
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={handleChange} value={values.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    name="password"
                                    label="Confirm Password"
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                            <LoadingButton loading={otpLoader} onClick={() => sendOtp(values.mobileNo)} fullWidth size="large" sx={{ mt: 4 }} variant="contained">
                                Continue
                            </LoadingButton>

                        </>
                            : <>
                                <MuiOtpInput value={otp} onChange={(value) => setOtp(value)} />
                                <LoadingButton loading={Loader} fullWidth size="large" type="submit" sx={{ mt: 4 }} variant="contained">
                                    Register
                                </LoadingButton>
                            </>}

                    </form>
                )}
            </Formik>

        </>
    );
}
