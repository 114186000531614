import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Card, Grid, IconButton, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import Bread from '../components/Bread/Bread';
import { AddSliderImage, DeleteSliderImage, GetAllSliderAction } from '../Store/Actions/SliderAction/SliderAction';
import { UseStateManager } from '../Context/StateManageContext';
import { GetFileUploaded } from '../Store/Actions/ImageUploadActions/UploadActions';
import instance from '../utils/_utils';


const HomeSlider = () => {

    const { currentUser } = UseStateManager();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllSliderAction());
    }, []);

    const SliderImages = useSelector((state) => state.GetAllSliderReducer);
    const imageUpload = useSelector((state) => state.UploadReducer)
    console.log(imageUpload?.fileName)


    const UploadSliderImage = async (e) => {
        const { name } = e.target;

        const file = e.target.files[0];

        try {
            // Create a FormData object to append the file
            const formData = new FormData();
            formData.append('file', file);

            // Make a POST request with Axios
            const response = await instance.post(`/util/upload/file/directly?fileName=${file.name}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                // Show success alert using SweetAlert2
                Swal.fire({
                    icon: 'success',
                    title: 'Upload Successful',
                    text: 'The file has been uploaded successfully!',
                });

                // Make additional requests or handle the response as needed
                const changeAvatar = await instance.get(`/slider/add-in-slider?imgurl=${response.data.fileName}`);

                if (changeAvatar.status === 200) {
                    await dispatch(GetAllSliderAction());
                    console.log(changeAvatar.data);
                } else {
                    console.log("Upload slider image failed");
                }
            }
        } catch (error) {
            // Show error alert using SweetAlert2
            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: 'An error occurred while uploading the file.',
            });

            console.log(error);
        }
    };


    const uploadImage = async () => {
        try {
            if (!imageUpload.fileName) {
                Swal.fire({
                    icon: 'warning',
                    title: 'No Image Selected',
                    text: 'Please select an image before uploading.',
                });
                return;
            }
            await dispatch(AddSliderImage(imageUpload?.fileName?.sliderImgUrl))
            console.log(imageUpload)
            await dispatch(GetAllSliderAction());
            Swal.fire({
                icon: 'success',
                title: 'Image Uploaded',
                text: 'Image has been successfully uploaded and saved to the database.',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while uploading the image. Please try again.',
            });
        }
    };


    const handleRemoveSliderImages = async (sliderId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                await dispatch(DeleteSliderImage(sliderId));
                Swal.fire(
                    'Deleted!',
                    'Your facility has been deleted.',
                    'success'
                );
                await dispatch(GetAllSliderAction());
            }
        } catch (error) {
            console.error('Error deleting facility:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete facility. Please try again.',
            });
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Bread icon={'solar:slider-vertical-line-duotone'} tabName={'Slider-Management'} />
                <Box>
                    <Button color='error' onClick={() => document.querySelector('.imageSelect').click()} variant={'contained'}>
                        Upload Slider Image
                    </Button>
                    <input className="imageSelect" type="file" name='sliderImgUrl' accept="image/*" onChange={UploadSliderImage} multiple style={{ display: 'none' }} />
                </Box>
            </Box>
            <Typography variant='h5' color={'#420D09'}>
                <b>Note: </b>Please select at least 3 images for the slider
            </Typography>

            <Grid container spacing={1} mt={2}>
                {SliderImages?.data?.map((item, index) => (
                    <Grid item xs={4} key={index}>
                        <Card sx={{ width: '100%', height: '15rem', display: 'grid', placeItems: 'center', color: '#ee2e24', border: '2px solid #ee2e24' }}>
                            <IconButton onClick={() => handleRemoveSliderImages(item._id)} sx={{ position: 'absolute', top: 0, right: 0, color: '#ee2e24' }}>
                                <DeleteOutlineIcon fontSize='large' />
                            </IconButton>
                            <div>
                                <img src={item?.img} alt={`Selected ${index}`} style={{ maxWidth: '100%', maxHeight: '300px' }} />
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div >
    );
};

export default HomeSlider;
