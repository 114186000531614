import * as constant from '../../Constants/adminConstants/allPropertyType';


const initialState = {
    isLoading: false,
    isError: false,
    data: null
}


export const AllPropertyTypeReducers = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_ALL_PROPERTY_TYPE_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GET_ALL_PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.GET_ALL_PROPERTY_TYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}

const initialStateOfAddProperty = {
    isLoading: false,
    isError: false,
    data: null
}


export const AddPropertyTypeReducers = (state = initialStateOfAddProperty, action) => {
    switch (action.type) {
        case constant.PUT_ALL_PROPERTY_TYPE_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.PUT_ALL_PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.PUT_ALL_PROPERTY_TYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}


const initialStateOfRemoveProperty = {
    isLoading: false,
    isError: false,
    data: []
}


export const RemovePropertyTypeReducers = (state = initialStateOfRemoveProperty, action) => {
    switch (action.type) {
        case constant.REMOVE_ALL_PROPERTY_TYPE_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.REMOVE_ALL_PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.REMOVE_ALL_PROPERTY_TYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}

const initialStateOfUpdateProperty = {
    isLoading: false,
    isError: false,
    data: null
}


export const UpdatePropertyTypeReducers = (state = initialStateOfUpdateProperty, action) => {
    switch (action.type) {
        case constant.UPDATE_ALL_PROPERTY_TYPE_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.UPDATE_ALL_PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.UPDATE_ALL_PROPERTY_TYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}