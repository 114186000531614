import instance from '../../../utils/_utils';
import * as constant from '../../Constants/NotificationConstants/NotificationConstants';

export const GetAllNotificationEventAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GETALL_NOTIFICATION_API_LOADING });
            const response = await instance.get(`/notify/new-notifications/event/get`);
            if (response.status === 200) {
                dispatch({ type: constant.GETALL_NOTIFICATION_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GETALL_NOTIFICATION_API_ERROR });
        }
    };
};

export const GetUserNotificationAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_USER_NOTIFICATION_API_LOADING });
            const response = await instance.get(`/notify/new-notifications/app-notifications/${id}`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_USER_NOTIFICATION_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_USER_NOTIFICATION_API_ERROR });
        }
    };
};

export const GetReadNotificationAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_READ_NOTIFICATION_API_LOADING });
            const response = await instance.get(`/notify/new-notifications/app-notifications/read/${id}`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_READ_NOTIFICATION_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_READ_NOTIFICATION_API_ERROR });
        }
    };
};

export const GetAllNotificationEventTempAction = (eventId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GETALL_NOTIFICATIONT_TEMP_API_LOADING });
            const response = await instance.get(`/notify/new-notifications/notification-event/getall?${eventId}`);
            if (response.status === 200) {
                dispatch({ type: constant.GETALL_NOTIFICATIONT_TEMP_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GETALL_NOTIFICATIONT_TEMP_API_ERROR });
        }
    };
};