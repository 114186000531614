import instance from '../../../utils/_utils'
import * as constant from '../../Constants/adminConstants/allRoomType'



export const GetAllRoomType = () => {
    return async (dispatch) => {
        dispatch({ type: constant.GET_ALL_ROOM_TYPE_LOADING })
        try {
            const response = await instance.get(`/roomtype/get`)
            if (response.status === 200) {
                dispatch({ type: constant.GET_ALL_ROOM_TYPE_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.GET_ALL_ROOM_TYPE_ERROR })
        }
    }
}

export const CreateRoomType = (formData) => {
    return async (dispatch) => {
        dispatch({ type: constant.CREATE_ROOM_TYPE_LOADING })
        try {
            const response = await instance.post(`/roomtype/add`, formData)
            if (response.status === 200) {
                console.log(response.data)
                dispatch({ type: constant.CREATE_ROOM_TYPE_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.CREATE_ROOM_TYPE_ERROR })
        }
    }
}

export const RemoveRoomTypeAction = (roomTypeId) => {
    console.log(roomTypeId)
    return async (dispatch) => {
        dispatch({ type: constant.REMOVE_ROOM_TYPE_LOADING })
        try {
            const response = await instance.get(`/roomtype/delete/${roomTypeId}`)
            if (response.status === 200) {
                dispatch({ type: constant.REMOVE_ROOM_TYPE_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.REMOVE_ROOM_TYPE_ERROR })
        }
    }
}

export const RemoveAllRoomTypeAction = () => {
    return async (dispatch) => {
        dispatch({ type: constant.REMOVE_ALL_ROOM_TYPE_LOADING })
        try {
            const response = await instance.delete(`/roomtype/deleteall`)
            if (response.status === 200) {
                console.log(response.data)
                dispatch({ type: constant.REMOVE_ALL_ROOM_TYPE_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.REMOVE_ALL_ROOM_TYPE_ERROR })
        }
    }
}